/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";
import ProductSenderFragment from "./ProductSenderFragment";

const ProductApplSSCCView = ({
                         request
                     }) => {


    return (

            <table className="table-fixed w-full shadow-lg bg-white border-collapse">
                <tbody>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Баркод/GTIN</th>
                    <td className="border px-8 py-4">{request && request.barcode}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Бүтээгдэхүүний Нэр</th>
                    <td className="border px-8 py-4">{request && request.name}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэгжийн жин /кг, гр, ш, /</th>
                    <td className="border px-8 py-4">{request && request.unit_weight}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Хайрцаг дахь тоо</th>
                    <td className="border px-8 py-4">{request && request.package_quantity}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Тавиур дахь тоо</th>
                    <td className="border px-8 py-4">{request && request.pallet_quantity}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Экспорт хийж буй улс</th>
                    <td className="border px-8 py-4">{request && request.exporting_country}</td>
                </tr>

                <ProductSenderFragment p={request}/>

                </tbody>
            </table>
    )

}


export default ProductApplSSCCView

