/**
 * Created by gantushig on 4/16/17.
 */

import React from "react";

import {GLN, GTIN, SSCC} from "./Constants";
import glnData from "./data/glns.json";
import {toast} from "react-toastify";


const ErrorValidationLabel = ({ txtLbl }) => (
    <label style={{ color: "red" }}>
        {txtLbl}
    </label>
);

const Field = ({
                   valid,
                   type,
                   fieldId,
                   fieldName,
                   typeMismatch,
                   formatErrorTxt,
                   requiredTxt
               }) => {
    const renderErrorLabel = !valid ? (
        <ErrorValidationLabel
            txtLbl={typeMismatch ? formatErrorTxt : requiredTxt}
        />
    ) : (
        ""
    );

    return (
        <>
            <input type={type} name={fieldId} placeholder={fieldName} required />
            <br />
            {renderErrorLabel}
            <br />
        </>
    );
};


const ProductApplEditComponent = ({
                                      product, onChangeField, onSave, onCancel,
                                      packaging_units, onChangePackagingUnit
                                  }) => {

    if ( product.barcode_type === undefined ) {
        product.barcode_type = "GTIN";
    }

    const locationOptions = () => {
        let items = [];
        glnData.locations.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };

    const locationTypeOptions = () => {
        let items = [];
        glnData.locationTypes.map(item=>{
            items.push(<option key={item.id} value={item.id}>{item.name}</option>);
        })
        return items;
    };


    const handleChangeField = (e) => {
        const { value, title, max } = e.target;
        if (max && max && value.length > max) {
            let msg = `${title} нийт ${max} тэмдэгтээс ихгүй байна.`;
            toast.warning(msg);
            return;
        }

        onChangeField(e);
    }
    return (
        <div className="my-4" >


            <div className="mb-4">
                {(product.barcode_type === GTIN || product.barcode_type === SSCC) &&
                <label className="block text-gray-700 text-sm font-bold mb-2">Бүтээгдэхүүний нэр / Тодорхойлолт *</label>
                }
                {(product.barcode_type === GLN) &&
                <label  className="block text-gray-700 text-sm font-bold mb-2">Байршлын нэр*</label>
                }
                <input name="name" title={"Бүтээгдэхүүний нэр / Тодорхойлолт"} max={500} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.name}
                       onChange={handleChangeField}  placeholder="Нэр оруулах"/>

            </div>

            <div className="mb-4">
                <label  className="block text-gray-700 text-sm font-bold mb-2">Зураасан код</label>
                <input name="barcode" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.barcode}
                       onChange={onChangeField}  placeholder="Зураасан код оруулах"/>

            </div>
            {product.barcode_type === GTIN &&
                <div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Бүтээгдэхүүний брэнд нэр* </label>
                        <input name="brand_name" title={"Бүтээгдэхүүний брэнд нэр"} max={70}
                               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.brand_name}
                               onChange={handleChangeField}  placeholder="Бүтээгдэхүүний брэнд нэр оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Савлалтын хэмжээ * / нэг л савлалт бичих, тоо оруулах / </label>
                        <input name="packaging" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="number" pattern="\d*" value={product.packaging}
                               onChange={onChangeField}  placeholder="Савлалтын хэмжээ оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Савлалтын хэмжих нэгж * </label>
<div className="inline-block relative">
                        <select name="packaging_unit"  className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" value={product.packaging_unit} onChange={onChangePackagingUnit}>
                            <option value="0"></option>
                            {packaging_units.map(unit => {
                                return (
                                    <option key={unit.code} value={unit.code}>{unit.name_mn} - {unit.code} ( {unit.name} )</option>
                                )
                            })
                            }
                        </select>
                        <div
                            className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                            <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                 viewBox="0 0 20 20">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                            </svg>
                        </div>
</div>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Орц найрлага *</label>
                        <textarea name="constituent"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.constituent}
                                  onChange={onChangeField}  placeholder="Орц найрлага оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Шинж чанар *</label>

                        <textarea name="characteristics" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  value={product.characteristics}
                                  onChange={onChangeField}  placeholder="Шинж чанар оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Зориулалт *</label>

                        <textarea name="handling" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={product.handling}
                                  onChange={onChangeField}  placeholder="Зориулалт оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label className="label">Хэрэглэх заавар *</label>

                        <textarea name="instruction" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={product.instruction}
                                  onChange={onChangeField}  placeholder="Хэрэглэх заавар оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Хадгалах нөхцөл/хугацаа *</label>
                        <textarea name="storage" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" value={product.storage}
                                  onChange={onChangeField}  placeholder="Хадгалах нөхцөл оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Аль улсаас гарал үүсэлтэй *</label>
                        <input  name="coo"   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.coo}
                                onChange={onChangeField} placeholder="Аль улсаас гарал үүсэлтэй оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Бүтээгдэхүүний зураг байршсан линк *</label>
                        <input  name="image_link"   title={"Бүтээгдэхүүний зураг байршсан линк"} max={2500} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.image_link}
                                onChange={handleChangeField} placeholder="Бүтээгдэхүүний зураг байршсан линк оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс *</label>
                        <input  name="exp_countries_member"   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.exp_countries_member}
                                onChange={onChangeField} placeholder="Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс оруулах"/>
                    </div>
                </div>
            }
            {(product.barcode_type === GLN) &&
                <div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Байршлыг сонгох*</label>

                            <div className="inline-block relative">
                                <select name="location"   className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline" value={product.location} onChange={onChangeField}>
                                <option value=""></option>
                                    {locationOptions()}
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </div>
                            </div>

                    </div>

                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Байршлын хаяг* </label>

                    </div>

                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Улс, хот *</label>
                        <input name="state"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" value={product.state}
                onChange={onChangeField}  placeholder="Улс, хот оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Дүүрэг/аймаг *</label>
                        <input name="aimak"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.aimak}
                onChange={onChangeField}  placeholder="Дүүрэг/аймаг оруулах"/>

                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Сум/хороо *</label>
                        <input name="soum"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.soum}
                onChange={onChangeField}  placeholder="Сум/хороо оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Утас, факс *</label>
                        <input name="phone"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" value={product.phone}
                onChange={onChangeField}  placeholder="Утас, факс оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">И-мэйл *</label>
                        <input name="email"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.email}
                onChange={onChangeField} placeholder="И-мэйл оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">Вэбсайт *</label>
                        <input name="web"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.web}
                onChange={onChangeField}  placeholder="Вэбсайт оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Сошиал хаяг *</label>
                        <input name="social"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.social}
                               onChange={onChangeField}  placeholder="Сошиал хаяг оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label  className="block text-gray-700 text-sm font-bold mb-2">ЗИП код (5 оронтой тоо) *</label>
                        <input name="zip_code"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.zip_code}
                onChange={onChangeField} placeholder="ЗИП код оруулах"/>
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2">Байршлын төрлийг сонгох *</label>

                            <div className="inline-block relative">
                                <select   className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"  name="location_type" value={product.location_type} onChange={onChangeField}>
                                    <option value=""></option>
                                    {locationTypeOptions()}
                                </select>
                                <div
                                    className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 20 20">
                                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                                    </svg>
                                </div>
                            </div>


                    </div>
                </div>
            }
            {(product.barcode_type === SSCC) &&
            <div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2">Нэгжийн жин /кг, гр, ш, / *</label>
                    <input name="unit_weight"   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.unit_weight}
                           onChange={onChangeField}  placeholder="Нэгжийн жин оруулах"/>
                </div>
                <div className="mb-4">
                    <label  className="block text-gray-700 text-sm font-bold mb-2">Хайрцаг дахь тоо *</label>
                    <input name="package_quantity"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.package_quantity}
                           onChange={onChangeField}  placeholder="Хайрцаг дахь тоо оруулах"/>
                </div>
                <div className="mb-4">
                    <label  className="block text-gray-700 text-sm font-bold mb-2">Тавиур дахь тоо *</label>
                    <input name="pallet_quantity" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" value={product.pallet_quantity}
                           onChange={onChangeField}  placeholder="Тавиур дахь тоо оруулах"/>
                </div>
                <div className="mb-4">
                    <label  className="block text-gray-700 text-sm font-bold mb-2">Экспорт хийж буй улс *</label>
                    <input name="exporting_country" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"  type="text" value={product.exporting_country}
                           onChange={onChangeField}  placeholder="Экспорт хийж буй улс оруулах"/>
                </div>

            </div>
            }
            <div className="mb-4">
                <label  className="block text-gray-700 text-sm font-bold mb-2">Илгээсэн: Нэр *</label>
                <input  name="sender_name"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.sender_name}
                        onChange={onChangeField} placeholder="Илгээсэн хүний нэр оруулах"/>

            </div>
            <div className="mb-4">
                <label  className="block text-gray-700 text-sm font-bold mb-2">Илгээсэн: Албан тушаал *</label>

                <input name="sender_position"   className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.sender_position}
                       onChange={onChangeField}
                       placeholder="Илгээсэн хүний албан тушаал оруулах"/>

            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">Илгээсэн: Утас *</label>

                <input  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"name="sender_phone"  type="text" value={product.sender_phone}
                       onChange={onChangeField} placeholder="Илгээсэн хүний утас оруулах"/>

            </div>
            <div className="mb-4">
                <label  className="block text-gray-700 text-sm font-bold mb-2">Илгээсэн: Емайл *</label>

                <input name="sender_email"  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" type="text" value={product.sender_email}
                       onChange={onChangeField} placeholder="Илгээсэн хүний емайл оруулах"/>

            </div>

            <div className="mb-4" >
                <button className="border rounded p-2 text-black" type="primary" onClick={() => onCancel()}>Болих</button>
                <button className="border rounded ml-2 p-2 text-white bg-orange-500" type="primary"
                        onClick={() => onSave()}>
                    Илгээх
                </button>
            </div>
        </div>
    )

}


export default ProductApplEditComponent

