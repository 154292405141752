import React, {useEffect, useState} from "react";
import {Endpoints} from "./Constants";
import {useNavigate} from "react-router-dom";

import axios from "./axiosConfig";
import {toast} from "react-toastify";
import CompanyViewComponent from "./components/CompanyViewComponent";
import axios2 from "./axiosConfig2";
import FileDownload from "js-file-download";


const CompanyView = () => {
    const navigate = useNavigate();
    const [data, setData] = useState({company: {}, contacts: []});
    useEffect(() => {
        async function getCompany() {
            const response = await axios.get(`${Endpoints.COMPANY}/own`);
            if (response.data.invoices.length > 0) {
                toast.success("Нэхэмжлэх ирсэн байна!");
                navigate("/invoices", {state: {invoices: response.data.invoices}})
            }
            setData(response.data);
        }

        getCompany();

    }, []);

    const handleDownloadDoc = (s) => {
        async function downloadDoc() {
            let config = {};
            config["url"] = `${Endpoints.COMPANY}/${data.company.id}/doc/${s}`;
            config["responseType"] = "arraybuffer";

            let response = await axios2(config);
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)
        }
        downloadDoc();
    };

    return (
        <CompanyViewComponent company={data.company} contacts={data.contacts} onDownloadDoc={handleDownloadDoc}/>
    );
    //
    // const aimakToString = (a) => {
    //     let ret = ""
    //     let ra = aimaksData.filter(b=>b.id === a);
    //     if (ra.length>0) {
    //         ret = ra[0].name;
    //     }
    //
    //     return ret
    // };
    //
    // const districtToString = (aimak, d) => {
    //     let ret = ""
    //     if (aimak) {
    //         let ra = aimaksData.filter(b=>b.id === aimak);
    //         if (ra.length>0) {
    //             let sa  = ra[0].units.filter(c=>c.id === d);
    //             if (sa.length > 0) {
    //                 ret = sa[0].name;
    //             }
    //         }
    //     }
    //     return ret
    //
    // };
    //
    // const showGender = (g) => {
    //     let ret = "";
    //     if (g === "MALE") {
    //         ret = "Ноён";
    //     } else if ( g === "FEMALE") {
    //         ret = "Хатагтай";
    //     }
    //
    //     return ret
    // };
    // const showPositionType = (g) => {
    //     let ret = "Бусад";
    //     if (g === "CEO") {
    //         ret = "Захирал";
    //     } else if ( g === "GS1") {
    //         ret = "Код хариуцсан ажилтан";
    //     } else if ( g === "ACC") {
    //         ret = "Нябо";
    //     }
    //
    //     return ret
    // };
    // const items = data.contacts.map(
    //     (p) => {
    //         return (
    //             <tr key={p.id} style={{marginBottom:'10px'}}>
    //                 <td>{p.counter}</td>
    //                 <td>{showGender(p.gender)} {p.surname} {p.name}</td>
    //                 <td>{showPositionType(p.position_type)}  / {p.position!==null ? ( p.position ): ""}</td>
    //                 <td>{p.phone} {p.fax !== "" && <span>Факс: {p.fax}</span>}</td>
    //                 <td> {p.email}</td>
    //             </tr>
    //         )
    //     });
    //
    // const handleDownloadDoc = (s) => {
    //     async function downloadDoc() {
    //         let config = {};
    //         config["url"] = `${Endpoints.COMPANY}/${company.id}/doc/${s}`;
    //         config["responseType"] = "arraybuffer";
    //
    //         let response = await axios1(config);
    //         const fileNameHeader = "x-suggested-filename";
    //         const suggestedFileName = response.headers[fileNameHeader]
    //         let fn = decodeURIComponent(suggestedFileName);
    //
    //         FileDownload(response.data, fn)
    //     }
    //     downloadDoc();
    // };
    //
    // return (
    //     <div className="p-2" style={{backgroundColor: "white"}}>
    //         <h2>Байгууллага</h2>
    //         <table className="table table-bordered">
    //             <tbody>
    //             <tr>
    //                 <th className="company-title">Нэр</th>
    //                 <td>{company && company.name}</td>
    //             </tr>
    //             <tr>
    //                 <th className="company-title">Нэр Англиар</th>
    //                 <td className="company-value">{company && company.name_english}</td>
    //             </tr>
    //             <tr>
    //                 <th className="company-title">Улсын бүртгэлийн дугаар</th>
    //                 <td className="company-value">{company && company.state_reg_number}</td>
    //             </tr>
    //             <tr>
    //                 <th className="company-title">Регистрийн дугаар</th>
    //                 <td className="company-value">{company && company.registration_number}</td>
    //             </tr>
    //             <tr>
    //                 <th>Байгуулагдсан огноо</th>
    //                 <td>{company && company.established &&
    //                     format(parseISO(company.established), "yyyy-MM-dd")
    //                    // dayjs.utc(company.established).format("MM/DD/YYYY")
    //                 }</td>
    //             </tr>
    //             <tr>
    //                 <th>Элссэн огноо</th>
    //                 <td>{company && company.joined &&
    //                     format(parseISO(company.joined), "yyyy-MM-dd")
    //                     //dayjs.utc(company.joined).format("MM/DD/YYYY")
    //                 }</td>
    //             </tr>
    //             <tr>
    //                 <th>Аймаг/Хот</th>
    //                 <td>{company && aimakToString(company.aimak)}</td>
    //             </tr>
    //             <tr>
    //                 <th>Дүүрэг</th>
    //                 <td>{company && districtToString(company.district)}</td>
    //             </tr>
    //             <tr>
    //                 <th>Сум/Хороо</th>
    //                 <td>{company && company.soum}</td>
    //             </tr>
    //             <tr>
    //                 <th>Хаяг</th>
    //                 <td>{company && company.address}</td>
    //             </tr>
    //             <tr>
    //                 <th>Утас</th>
    //                 <td>{company && company.phone}</td>
    //             </tr>
    //             <tr>
    //                 <th>Шуудангийн хаяг</th>
    //                 <td>{company && company.postal_address}</td>
    //             </tr>
    //             <tr>
    //                 <th>Мэйл</th>
    //                 <td>{company && company.email}</td>
    //             </tr>
    //             <tr>
    //                 <th>Сошиал хаяг</th>
    //                 <td>{company && company.social}</td>
    //             </tr>
    //             <tr>
    //                 <th>Вебхуудас</th>
    //                 <td>{company && company.web}</td>
    //             </tr>
    //             <tr>
    //                 <th>Үйл ажиллагааны чиглэл</th>
    //                 <td>{company && company.sector_name}</td>
    //             </tr>
    //
    //             <tr>
    //                 <th>Албан Тоот</th>
    //                 <td>
    //                     { company && company.exists_letter_att &&
    //                     <div>
    //                         <button className="btn btn-link" onClick={(e) => handleDownloadDoc(1)}>{company.letter_file_name}</button>
    //
    //                     </div>
    //                     }
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <th>Гэрчилгээ/Үнэмлэх</th>
    //                 <td>
    //                     { company && company.exists_doc_att &&
    //                     <div>
    //                         <button className="btn btn-link" onClick={(e) => handleDownloadDoc(2)}>{company.doc_file_name}</button>
    //                     </div>
    //                     }
    //
    //                 </td>
    //             </tr>
    //
    //             <tr>
    //                 <th>Гэрчилгээ/Үнэмлэх / Арын Хуудас/</th>
    //                 <td>
    //                     { company && company.exists_doc2_att &&
    //                     <div>
    //                         <button className="btn btn-link" onClick={(e) => handleDownloadDoc(3)}>{company.doc2_file_name}</button>
    //                     </div>
    //                     }
    //
    //                 </td>
    //             </tr>
    //             <tr>
    //                 <th className="company-title">GEPIR</th>
    //                 <td className="company-value"><input type="checkbox" disabled
    //                                                      checked={company && company.is_gepir}/></td>
    //             </tr>
    //             </tbody>
    //         </table>
    //         <br/>
    //         <h2>Хариуцсан ажилтан</h2>
    //         <br/>
    //         <table className="table table-bordered">
    //             <thead>
    //             <tr>
    //                 <th>#</th>
    //                 <th>Нэр</th>
    //                 <th>Албан Тушаал</th>
    //                 <th>Утас</th>
    //                 <th>Мэйл</th>
    //             </tr>
    //             </thead>
    //             <tbody>
    //             {items}
    //             </tbody>
    //         </table>
    //         {/*{isTimeout ? "Timeout" : "Active"}*/}
    //     </div>
    // )
    //
}


export default CompanyView




