/**
 * Created by gantushig on 11/6/15.
 */

import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import ReactPaginate from "react-paginate";

import {Endpoints} from './Constants';
import {format, parseISO} from 'date-fns'
import axios from "./axiosConfig";
import axios2 from './axiosConfig2'
import "./scss/my.scss";
const FileDownload = require('js-file-download');

const BarcodesPage = () => {
    let {gcpId} = useParams();
    const [data, setData] = useState({barcodes:[],meta : {totalPages:0, totalElements:0, page:0,size:25}});

    const [page, setPage] = useState(0);
    const [toggle, setToggle] = useState(false);

    const pagesize = 25;

    useEffect(() => {
        async function getBarcodes() {
            const response = await axios.get(`${Endpoints.GCP}/${gcpId}/barcodes?page=${page}&pagesize=${pagesize}`);
            setData(response.data);
        }
        getBarcodes();

    }, [toggle]);

    const handleDownload = (id) => {
        console.log('download');
        async function download1() {

            let config = {};
            config["url"] = `${Endpoints.BARCODE}/${id}/download`;
            config["responseType"] = "arraybuffer";
            let response = await axios2(config);
            const fileNameHeader = "x-suggested-filename";
            //const fileNameHeader = "content-disposition";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)

        }
        download1();
    };




    const handlePageClick = (data) => {
        let selected = data.selected;
        setPage(data.selected);

        setToggle(!toggle);
    };

    let counter = Number(data.meta.page) * Number(data.meta.size);
    const items = data.barcodes.map(
        (barcode) => {
            counter++;
            return (
                <tr key={barcode.id}>
                    <td className="border px-8 py-4">{counter}</td>
                    <td className="border px-8 py-4">
                        {barcode.issued && format(parseISO(barcode.issued), "yyyy-MM-dd")}
                    </td>
                    <td className="border px-8 py-4">{barcode.filename}</td>
                    <td className="border px-8 py-4">
                        <button className="text-blue-500 underline" onClick={(е)=>handleDownload(barcode.id)}>Татаж авах</button>
                    </td>
                </tr>
            )
        });

    return (
        <div className="m-4">
            <table className="w-full shadow-lg bg-white border-collapse">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Олгосон</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Файлын нэр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4"></th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            <nav className="my-4">
                <ReactPaginate previousLabel={"Өмнөх"}
                               nextLabel={"Дараах"}
                               initialPage={0}
                               breakLabel={<a href="">...</a>}
                               breakClassName={""}
                               pageCount={data.meta.totalPages}
                               marginPagesDisplayed={2}
                               pageRangeDisplayed={5}
                               activeClassName="bg-blue-300 hover:bg-blue-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                               pageClassName="text-gray-500 px-3 py-2 leading-tight border border-gray-300 hover:bg-gray-100 hover:text-gray-700"
                               pageLinkClassName=""
                               onPageChange={(e) => handlePageClick(e)}
                               previousClassName={""}
                               previousLinkClassName="block px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               nextClassName={""}
                               nextLinkClassName="block px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                               containerClassName="inline-flex items-center -space-x-px"
                />
            </nav>
            <br/><br/>

        </div>
    )
}

export default BarcodesPage


