/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";

const ProductSenderFragment = ({
                             p
                         }) => {


    return (
        <>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Илгээсэн: Нэр</th>
                <td className="border px-8 py-4">{p && p.sender_name}</td>
            </tr>

            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Илгээсэн: Албан тушаал</th>
                <td className="border px-8 py-4">{p && p.sender_position}</td>
            </tr>

            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Илгээсэн: Утас</th>
                <td className="border px-8 py-4">{p && p.sender_phone}</td>
            </tr>

            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Илгээсэн: Имэйл </th>
                <td className="border px-8 py-4">{p && p.sender_email}</td>
            </tr>
        </>
    )

}


export default ProductSenderFragment

