import React, {useEffect, useRef, useState} from 'react';
import AuthContext from './AuthContext'
import {Endpoints, LOGGED_IN} from "./Constants";
import axios from './axiosConfig'
import {
    Routes,
    Route,
    NavLink,
    useNavigate, useLocation,
} from 'react-router-dom';
import IdleTimer2 from "./IdleTimer2";


export const AuthProvider = ({children}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem(LOGGED_IN));
    const [error, setError] = useState(null);

    const handleLogin = async (username, password) => {

        const loggedIn = localStorage.getItem(LOGGED_IN);
        if (loggedIn) {
            setIsAuthenticated(true);//token);
            const origin = location.state?.from?.pathname || '/dashboard';
            navigate(origin);
            return;
        }

        const creds = {
            username: username,
            password: password
        };

        let header = {}

        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.LOGIN_URL}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(creds);
        try {
            const response = await axios(config)

            if (response.status >= 400) {
                setError("Bad response from server");
                //throw new Error("Bad response from server");
            } else {
                setError(null);
            }
            if (!response.status === 200) {
                // If there was a problem, we want to
                // dispatch the error condition
                //return Promise.reject(user)
            } else {
                setIsAuthenticated(true);//token);
                localStorage.setItem(LOGGED_IN, true);
                const origin = location.state?.from?.pathname || '/company';
                navigate(origin);
                //navigate('/dashboard')
            }
        } catch (error) {
            setError("Хэрэглэгчийн код, нууц үгээ шалгана уу");//error.message);
            //throw new Error(error.message);
        }

//    const token = await fakeAuth();


    };

    const handleLogout = async () => {

        let endpoint = Endpoints.LOGOUTV1_URL;
        setIsAuthenticated(false);
        setError(null);
        localStorage.removeItem(LOGGED_IN)

        const resp = await axios.get(endpoint)
        //setToken(null);
    };

    const value = {
        isAuthenticated,
        onLogin: handleLogin,
        onLogout: handleLogout,
        error:error,
    };


    // const currentPath = location.pathname;
    // let timeout = null;
    // const ref = useRef(null);
    //
    //
    // useEffect(() => {
    //     console.log("path changed:" + currentPath)
    //
    //     // initiate timeout
    //     restartAutoReset();
    //
    //     const handleMouseMove = (ev) => {
    //         console.log("mouse moved")
    //         restartAutoReset();
    //     };
    //
    //     const element = ref.current;
    //     // listen for mouse events
    //     element.addEventListener('mousemove', handleMouseMove);
    //
    //     // cleanup
    //     return () => {
    //         if (timeout) {
    //             clearTimeout(timeout);
    //             element.removeEventListener('mousemove', handleMouseMove);
    //         }
    //     };
    // }, [currentPath]);
    //
    // const restartAutoReset = () => {
    //     if (timeout) {
    //         clearTimeout(timeout);
    //     }
    //     timeout = setTimeout(() => {
    //         //goBackToHome();
    //     }, 1000 * 60);
    // };

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};