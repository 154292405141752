/**
 * Created by gantushig on 1/30/16.
 */

import keyMirror from 'keymirror';

// PROD
export const BASE_URL= 'https://api2.gs1mongolia.org/';

// DEV
//export const BASE_URL= 'http://localhost:3000/';


//export const BASE_URL= 'https://api.gs1mongolia.org/';
//export const BASE_URL= 'http://172.104.86.125:3000/';
//export const BASE_URL= 'http://gs1api.inextmon.com/';
//export const BASE_URL= 'http://192.168.1.24:8090/';
//export const BASE_URL= 'http://192.168.1.121:8090/';
//export const BASE_URL= 'http://192.168.1.6:8090/';
//export const BASE_URL= 'http://192.168.0.101:8090/';
//export const BASE_URL= 'http://192.168.0.102:8090/';
//export const BASE_URL= 'http://192.168.8.2:3000/';
//export const BASE_URL= 'http://192.168.8.4:8090/';
//export const BASE_URL= 'http://192.168.8.5:8090/';

export const FB_APP_ID = '843444899138235';

export const TOKEN = "gs1portal_token"
export const LOGGED_IN = "logged_in";
export const GLN = "GLN"
export const GTIN = "GTIN"
export const SSCC = "SSCC"

export const ActionTypes =  keyMirror({
    INITIALIZE:null,
    ADD_ITEM: null,
    REMOVE_ITEM: null,
    INCREASE_ITEM:null,
    DECREASE_ITEM:null,
    RECEIVE_RAW_USERS:null,
    RECEIVE_INTERESTS:null,
    RECEIVE_FEED:null,
    CLICK_MENU:null,
    RECEIVE_MENU:null,
    POST_CREATED:null,

    FAILURE:null,

    ADD_ORG:null,
    EDIT_ORG:null,
    REMOVE_ORG:null,
    ORGS_READ:null,
    ORGS_READ_SUCCESS:null,
    ORGS_CREATE_SUCCESS:null,

    WRITE_EXPENSE:null,
    WRITE_INCOME:null,
    WRITE_TRANSFER:null,
    QUERY_ACCOUNTS:null,
    QUERY_PROJECTS:null,
    QUERY_TRANSACTIONS:null,

    LOGIN:null,
    LOGIN_REQUEST:null,
    LOGIN_SUCCESS:null,
    LOGIN_FAILURE:null,
    VERIFY_FAILURE:null,
    LOGOUT:null,

    VERIFY_TOKEN:null,
    REGISTER:null,
    REGISTER_SUCCESS:null,
    REGISTER_FAILURE:null,

    START_RTM:null,
    START_RTM_SUCCESS:null,
    START_RTM_FAILURE:null,

    LOAD_FEES_REQUEST:null,
    LOAD_FEES_SUCCESS:null,
    LOAD_FEES_FAILURE:null,

    LOAD_SECTORS_REQUEST:null,
    LOAD_SECTORS_SUCCESS:null,
    LOAD_SECTORS_FAILURE:null,


    LOAD_CLASSIF_MAIN_REQUEST:null,
    LOAD_CLASSIF_MAIN_SUCCESS:null,
    LOAD_CLASSIF_MAIN_FAILURE:null,


    LOAD_CLASSIF_SUB_REQUEST:null,
    LOAD_CLASSIF_SUB_SUCCESS:null,
    LOAD_CLASSIF_SUB_FAILURE:null,


    LOAD_CLASSIF_UNIT_REQUEST:null,
    LOAD_CLASSIF_UNIT_SUCCESS:null,
    LOAD_CLASSIF_UNIT_FAILURE:null,


    LOAD_COMPANIES_REQUEST:null,
    LOAD_COMPANIES_SUCCESS:null,
    LOAD_COMPANIES_FAILURE:null,

    LOAD_BARCODES_REQUEST:null,
    LOAD_BARCODES_SUCCESS:null,
    LOAD_BARCODES_FAILURE:null,

    UPLOAD_BARCODE_REQUEST:null,
    UPLOAD_BARCODE_SUCCESS:null,
    UPLOAD_BARCODE_FAILURE:null,

    DELETE_BARCODE_REQUEST:null,
    DELETE_BARCODE_SUCCESS:null,
    DELETE_BARCODE_FAILURE:null,

    LOAD_PAYMENTS_REQUEST:null,
    LOAD_PAYMENTS_SUCCESS:null,
    LOAD_PAYMENTS_FAILURE:null,


    GET_COMPANY_REQUEST:null,
    GET_COMPANY_SUCCESS:null,
    GET_COMPANY_FAILURE:null,

    UPLOAD_COMPANY_DOC_REQUEST:null,
    UPLOAD_COMPANY_DOC_SUCCESS:null,
    UPLOAD_COMPANY_DOC_FAILURE:null,

    DOWNLOAD_COMPANY_DOC_REQUEST:null,
    DOWNLOAD_COMPANY_DOC_SUCCESS:null,
    DOWNLOAD_COMPANY_DOC_FAILURE:null,

    DELETE_COMPANY_DOC_REQUEST:null,
    DELETE_COMPANY_DOC_SUCCESS:null,
    DELETE_COMPANY_DOC_FAILURE:null,

    LOAD_PRODUCTS_REQUEST:null,
    LOAD_PRODUCTS_SUCCESS:null,
    LOAD_PRODUCTS_FAILURE:null,

    GET_PRODUCT_REQUEST:null,
    GET_PRODUCT_SUCCESS:null,
    GET_PRODUCT_FAILURE:null,

    CLONE_PRODUCT_REQUEST:null,
    CLONE_PRODUCT_SUCCESS:null,
    CLONE_PRODUCT_FAILURE:null,

    GET_PRODUCT_IMAGE_REQUEST:null,
    GET_PRODUCT_IMAGE_SUCCESS:null,
    GET_PRODUCT_IMAGE_FAILURE:null,

    UPLOAD_PRODUCT_IMAGE_REQUEST:null,
    UPLOAD_PRODUCT_IMAGE_SUCCESS:null,
    UPLOAD_PRODUCT_IMAGE_FAILURE:null,


    DELETE_PRODUCT_IMAGE_REQUEST:null,
    DELETE_PRODUCT_IMAGE_SUCCESS:null,
    DELETE_PRODUCT_IMAGE_FAILURE:null,


    CREATE_PRODUCT_REQUEST:null,
    CREATE_PRODUCT_SUCCESS:null,
    CREATE_PRODUCT_FAILURE:null,

    UPDATE_PRODUCT_REQUEST:null,
    UPDATE_PRODUCT_SUCCESS:null,
    UPDATE_PRODUCT_FAILURE:null,

    DELETE_PRODUCT_REQUEST:null,
    DELETE_PRODUCT_SUCCESS:null,
    DELETE_PRODUCT_FAILURE:null,


    LOAD_PRODUCT_REQUESTS_REQUEST:null,
    LOAD_PRODUCT_REQUESTS_SUCCESS:null,
    LOAD_PRODUCT_REQUESTS_FAILURE:null,

    GET_PRODUCT_APPL_REQUEST:null,
    GET_PRODUCT_APPL_SUCCESS:null,
    GET_PRODUCT_APPL_FAILURE:null,

    LOAD_PACKAGING_UNITS_REQUEST:null,
    LOAD_PACKAGING_UNITS_SUCCESS:null,
    LOAD_PACKAGING_UNITS_FAILURE:null,

    CLONE_PRODUCT_APPL_REQUEST:null,
    CLONE_PRODUCT_APPL_SUCCESS:null,
    CLONE_PRODUCT_APPL_FAILURE:null,

    INIT_PRODUCT_APPL:null,

    CREATE_PRODUCT_APPL_REQUEST:null,
    CREATE_PRODUCT_APPL_SUCCESS:null,
    CREATE_PRODUCT_APPL_FAILURE:null,


    CHANGE_TEMP_PRODUCT_APPL:null,
    CHANGE_TEMP_GLN_PRODUCT_APPL:null,
    CHANGE_TEMP_SSCC_PRODUCT_APPL:null,

    UPDATE_PRODUCT_REQUEST_REQUEST:null,
    UPDATE_PRODUCT_REQUEST_SUCCESS:null,
    UPDATE_PRODUCT_REQUEST_FAILURE:null,

    CHANGE_PRODUCT_REQUEST_REQUEST:null,
    CHANGE_PRODUCT_REQUEST_SUCCESS:null,
    CHANGE_PRODUCT_REQUEST_FAILURE:null,

    DELETE_PRODUCT_APPL_REQUEST:null,
    DELETE_PRODUCT_APPL_SUCCESS:null,
    DELETE_PRODUCT_APPL_FAILURE:null,

    GET_INTEREST:null,
    GET_INTEREST_SUCCESS:null,
    GET_INTEREST_FAILURE:null,

    INVITE_RESOLVE:null,
    INVITE_RESOLVE_SUCCESS:null,
    INVITE_RESOLVE_FAILURE:null,
    INVITE_ACCEPT:null,
    INVITE_ACCEPT_SUCCESS:null,
    INVITE_ACCEPT_FAILURE:null,

    INSTANT_INVITE_CREATE:null,
    INSTANT_INVITE_CREATE_SUCCESS:null,
    INSTANT_INVITE_CREATE_FAILURE:null,


    INVITE_SUCCESS:null,
    INVITE_FAILURE:null,

    RECEIVED_INVITE_TOKEN:null,

    FORGOT_PASSWORD_SENT:null,

    EDIT_ORG_REQUEST:null,
    EDIT_ORG_SUCCESS:null,
    EDIT_ORG_FAILURE:null,

    ORG_AGENTS_REQUEST:null,
    ORG_AGENTS_SUCCESS:null,
    ORG_AGENTS_FAILURE:null,

    AGENT_HOUSES_REQUEST:null,
    AGENT_HOUSES_SUCCESS:null,
    AGENT_HOUSES_FAILURE:null,

    CHECK_USER_REQUEST:null,
    CHECK_USER_SUCCESS:null,
    CHECK_USER_FAILURE:null,


    A_POST_REQUEST:null,
    A_POST_SUCCESS:null,
    A_POST_FAILURE:null,

    POSTS_REQUEST:null,
    POSTS_SUCCESS:null,
    POSTS_FAILURE:null,

    COMMENTS_REQUEST:null,
    COMMENTS_SUCCESS:null,
    COMMENTS_FAILURE:null,

    HISTORY_REQUEST:null,
    HISTORY_SUCCESS:null,
    HISTORY_FAILURE:null,

    DISTRICTS_REQUEST:null,
    DISTRICTS_SUCCESS:null,
    DISTRICTS_FAILURE:null,

    LOGOUT_REQUEST:null,
    LOGOUT_SUCCESS:null,
    LOGOUT_FAILURE:null,

    LOAD_ME_REQUEST:null,
    LOAD_ME_SUCCESS:null,
    LOAD_ME_FAILURE:null,

    ADD_ORG_REQUEST:null,
    ADD_ORG_SUCCESS:null,
    ADD_ORG_FAILURE:null,

    LOAD_APPTS_REQUEST:null,
    LOAD_APPTS_SUCCESS:null,
    LOAD_APPTS_FAILURE:null,

    GET_APPT_REQUEST:null,
    GET_APPT_SUCCESS:null,
    GET_APPT_FAILURE:null,

    LOAD_WMS_REQUEST:null,
    LOAD_WMS_SUCCESS:null,
    LOAD_WMS_FAILURE:null,

    LOAD_READINGS_REQUEST:null,
    LOAD_READINGS_SUCCESS:null,
    LOAD_READINGS_FAILURE:null,

    LOAD_READINGS_NEW_REQUEST:null,
    LOAD_READINGS_NEW_SUCCESS:null,
    LOAD_READINGS_NEW_FAILURE:null,

    LOAD_READINGS_OWN_REQUEST:null,
    LOAD_READINGS_OWN_SUCCESS:null,
    LOAD_READINGS_OWN_FAILURE:null,

    LOAD_ONE_READING_REQUEST:null,
    LOAD_ONE_READING_SUCCESS:null,
    LOAD_ONE_READING_FAILURE:null,

    LOAD_INVOICES_REQUEST:null,
    LOAD_INVOICES_SUCCESS:null,
    LOAD_INVOICES_FAILURE:null,

    LOAD_INVOICES_OWN_REQUEST:null,
    LOAD_INVOICES_OWN_SUCCESS:null,
    LOAD_INVOICES_OWN_FAILURE:null,

    LOAD_DOCUMENTS_REQUEST:null,
    LOAD_DOCUMENTS_SUCCESS:null,
    LOAD_DOCUMENTS_FAILURE:null,

    GET_INVOICE_REQUEST:null,
    GET_INVOICE_SUCCESS:null,
    GET_INVOICE_FAILURE:null,

    FETCH_ALL_INVOICE_REQUEST:null,
    FETCH_ALL_INVOICE_SUCCESS:null,
    FETCH_ALL_INVOICE_FAILURE:null,

    PRINT_INVOICE_REQUEST:null,
    PRINT_INVOICE_SUCCESS:null,
    PRINT_INVOICE_FAILURE:null,

    DOWNLOAD_BARCODE_REQUEST:null,
    DOWNLOAD_BARCODE_SUCCESS:null,
    DOWNLOAD_BARCODE_FAILURE:null,

    LOAD_SECTORS_ALL_REQUEST:null,
    LOAD_SECTORS_ALL_SUCCESS:null,
    LOAD_SECTORS_ALL_FAILURE:null
});


export const Pages =  keyMirror({
    HOMELIST: null,
    GROUPS: null,
    MESSAGES: null,
    PROFILE: null,
    READING: null,
    BILLING: null,
});

//const BASE_WS_URL = 'ws://192.168.1.121:9000/';

// const BASE_URL= 'http://192.168.1.121:9000/';
// const BASE_WS_URL = 'ws://192.168.1.121:9000/';


export const Endpoints  =  {
    LOGIN_URL:'api/login',
    LOGINACC_URL:'api/loginacc',
    LOGOUTV1_URL:'v1.0/logout',
    LOGOUT_URL:'logout',
    SIGNUP_URL:'signup',
    VERIFY:'verify_token',
    FORGOT_PASSWORD:'forgot',
    RESET_PASSWORD:'reset',

    COMPANIES:'v1.0/companies',
    COMPANY:'v1.0/company',
    CONTACT:'v1.0/contact',
    PRODUCT_IMAGES:'v1.0/product-images',

    SECTORS:'v1.0/sectors',
    SECTOR:'v1.0/sector',

    FEES:'v1.0/fees',

    CLASSIF:'v1.0/classif',
    PACKAGING_UNITS:'v1.0/packaging-units',

    GCP:'v1.0/gcp',
    PRODUCTS:'v1.0/products',
    PRODUCT:'v1.0/product',

    PRODUCT_REQUESTS:'v1.0/product-reqs',
    PRODUCT_REQUEST:'v1.0/product-req',

    PRODUCT_IMAGE:'v1.0/product-image',

    BARCODE:'v1.0/barcode',
    NOTE:'v1.0/note',

    INVOICES:'v1.0/invoices',
    INVOICE:'v1.0/invoice',
    INVOICE_ADMIN:'v1.0/invoice-admin',


    ACCOUNTKIT_LOGIN:'https://www.accountkit.com/v1.0/basic/dialog/sms_login/',
    ACCOUNTKIT_REDIRECT:'api/loginacc',
    //ACCOUNTKIT_REDIRECT_BASIC:ACC_KIT_'acclogin',
    ME:'api/me',


}

