/**
 * Created by gantushig on 4/16/17.
 */

import React, {useState} from "react";
import {useAuth} from "./useAuth";
import logo from "./images/gs1mn-logo2.png"

const LoginEmail = () => {
    const { onLogin, error} = useAuth();
    const [hidden, setHidden] = useState(true);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    //const [error, setError] = useState("");

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    };

    const handleChangePassword = (e) => {
        setPassword(e.target.value)
    };


    const handleSend = (event) => {
        event.preventDefault();
        console.log('send');
        onLogin(email, password);
        //dispatch(loginUser(email, password));
    };

    const toggleShow = () => {
        setHidden(!hidden);
    }
    // useEffect(() => {
    //     if (sauth.isAuthenticated) {
    //         history.push("/")
    //     }
    // }, [sauth.isAuthenticated]);


        return (
            <>
                <div className="flex items-center  w-full p-0 bg-gray-100 h-28">
                    <a href="/">
                        <img alt="GS1 logo" className="block p-4"
                             src={logo}/>
                    </a>
                    <p className="block">
                        <strong className="font-bold">
                            Welcome to GS1 Mongolia
                        </strong>
                        <br/>
                        The Global Language of Business</p>
                </div>
                    <div className="flex  justify-center">
                        <div className="bg-white shadow-md rounded px-8 w-full md:w-1/3 pt-6 pb-8 mb-4 mt-4">
                            <h2 className="my-4 font-bold" > GS1 Mongolia Members</h2>

<form onSubmit={handleSend}>
                            <div className="mb-4">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="username">
                                    Хэрэглэгч
                                </label>
                                <input
                                    type="text"
                                    id="username"
                                    name="username"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="Хэрэглэгчийн код"
                                    value={email}
                                    onChange={(e) => handleChangeEmail(e)}/>
                            </div>
                            <div className="mb-6">
                                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                                    Нууц үг
                                </label>
                                <input
                                    type={hidden ? "password" : "text"}
                                    id="password"
                                    name="password"
                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                                    placeholder="******************"
                                    value={password}
                                    onChange={(e) => handleChangePassword(e)}
                                />
                                {/*<p className="text-red-500 text-xs italic">Нууц үгээ оруулна уу.</p>*/}
                            </div>
    {/*onClick={() => handleSend()}*/}
                            <div className="flex items-center justify-between">
                                <button type="submit"
                                    className="bg-blue-700 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded"
                                >
                                    Нэвтрэх
                                </button>
                            </div>
</form>
                            {/*<div className="form-group">*/}
                            {/*    <input className="form-control" type="text" value={email}*/}
                            {/*           onChange={(e) => handleChangeEmail(e)} placeholder="Username"/>*/}
                            {/*</div>*/}

                            {/*<div className="form-group">*/}
                            {/*    <div className="input-group">*/}
                            {/*        <input className="form-control"*/}
                            {/*               type={hidden ? "password" : "text"}*/}
                            {/*               value={password}*/}
                            {/*               onChange={(e) => handleChangePassword(e)} placeholder="Password"/>*/}


                            {/*        <div className="input-group-append">*/}
                            {/*            <button className="btn  btn-outline-secondary" onClick={() => toggleShow()}>*/}


                            {/*                {hidden &&*/}
                            {/*                <svg className="bi bi-eye-slash-fill" width="1em" height="1em"*/}
                            {/*                     viewBox="0 0 16 16" fill="currentColor"*/}
                            {/*                     xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path*/}
                            {/*                        d="M10.79 12.912l-1.614-1.615a3.5 3.5 0 01-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 002.79-.588zM5.21 3.088A7.028 7.028 0 018 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 00-4.474-4.474L5.21 3.089z"/>*/}
                            {/*                    <path*/}
                            {/*                        d="M5.525 7.646a2.5 2.5 0 002.829 2.829l-2.83-2.829zm4.95.708l-2.829-2.83a2.5 2.5 0 012.829 2.829z"/>*/}
                            {/*                    <path fillRule="evenodd"*/}
                            {/*                          d="M13.646 14.354l-12-12 .708-.708 12 12-.708.708z"*/}
                            {/*                          clipRule="evenodd"/>*/}
                            {/*                </svg>*/}
                            {/*                }*/}
                            {/*                {!hidden &&*/}
                            {/*                <svg className="bi bi-eye-fill" width="1em" height="1em" viewBox="0 0 16 16"*/}
                            {/*                     fill="currentColor" xmlns="http://www.w3.org/2000/svg">*/}
                            {/*                    <path d="M10.5 8a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"/>*/}
                            {/*                    <path fillRule="evenodd"*/}
                            {/*                          d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7z"*/}
                            {/*                          clipRule="evenodd"/>*/}
                            {/*                </svg>*/}
                            {/*                }*/}

                            {/*            </button>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}
                            {/*<button className="btn btn-primary btn-block" type="primary"*/}
                            {/*        onClick={() => handleSend()}>*/}
                            {/*    Login*/}
                            {/*</button>*/}
                            <div style={{margin:"5px"}}><span style={{color:"red", fontSize:"smaller"}}>{error}</span></div>

                        </div>
                        <div className="col-sm">

                        </div>

                    </div>

            </>
        )
//    }
}


export default LoginEmail;