
import {ActionTypes, Endpoints} from '../Constants';
import axios from '../axiosConfig'
import axios2 from '../axiosConfig2'
import qs from "query-string";

const fetchCompanySuccess = (status, company, gcps, contacts, invoices) => {
    return {
        type: ActionTypes.GET_COMPANY_SUCCESS,
        status,
        company,
        gcps,
        contacts,
        invoices
    }
};


export const fetchOwnCompany = (dispatch)  => {

    return new Promise(function (resolve, reject) {

        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${Endpoints.COMPANY}/own`;

        return axios(config)
            .then(response => {

                //let p = response.data.gcps.reduce((map, obj) => (map[obj.id] = obj, map), {});

                dispatch(fetchCompanySuccess(response.data.status, response.data.company, response.data.gcps, response.data.contacts, response.data.invoices))
                resolve(response)
            })
            .catch(error => {

                reject(error);
            });
    });
}



const downloadCompanyDocRequest = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_DOC_REQUEST
    }
};
const downloadCompanyDocSuccess = () => {
    return {
        type: ActionTypes.DOWNLOAD_COMPANY_DOC_SUCCESS
    }
};

export function downloadCompanyDoc(dispatch, id, side) {

        dispatch(downloadCompanyDocRequest())
        let header = {};
        let config = {};
        config["headers"] = header;
        config["url"] = `${Endpoints.COMPANY}/${id}/doc/${side}`;
        config["responseType"] = "arraybuffer";

        return axios(config)
            .then(response => {
                dispatch(downloadCompanyDocSuccess())
                const fileNameHeader = "x-suggested-filename";
                const suggestedFileName = response.headers[fileNameHeader]
                let fn = decodeURIComponent(suggestedFileName);

                //FileDownload(response.data, fn)

            })
            .catch(error => {

                // if (error.response === undefined) {
                //     dispatch(failure("Error", error.message));
                // } else {
                //     dispatch(failure(error.response.data.error, error.response.data.message));
                // }
            });
}


const fetchProductsRequest = () => {
    return {
        type: ActionTypes.LOAD_PRODUCTS_REQUEST
    }
};

const fetchProductsSuccess = (products, meta) => {
    return {
        type: ActionTypes.LOAD_PRODUCTS_SUCCESS,
        products,
        meta
    }
};

export const fetchProducts = (name, barcode, page, pagesize, order, asc) => {

    return (dispatch) => {

        let header = {};
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;

        // let query = {};
        // query.name = name;
        // query.barcode = barcode;
        // query.page = page;
        // query.size = pagesize;
        // query.order = order;
        // query.asc = asc;

        let url = `${Endpoints.PRODUCTS}/own/?` + qs.stringify({name:name, barcode:barcode, page:page, pagesize:pagesize, order:order, asc:asc}, {skipNull:true})
        config["url"] = url;

        return axios(config)
            .then(response => {
                dispatch(fetchProductsSuccess(response.data.products, response.data.meta))
            })
            .catch(error => {
                throw(error);
            });
    };
}




const fetchProductRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_REQUEST
    }
};

const fetchProductSuccess = (status, product) => {
    return {
        type: ActionTypes.GET_PRODUCT_SUCCESS,
        status,
        product
    }
};

export const fetchProduct = (token, productId) => {

    return (dispatch) => {
        dispatch(fetchProductRequest());
        let header = {};
        header["Authorization"] = token;
        header["Accept"] = "application/json";
        header["Content-Type"] = "application/json";

        let config = {};
        config["headers"] = header;
        config["url"] = `${Endpoints.PRODUCT}/${productId}`;

        return axios(config)
            .then(response => {
                dispatch(fetchProductSuccess(response.data.status, response.data.product))
            })
            .catch(error => {
                throw(error);
            });
    };
}




const fetchProductApplRequest = () => {
    return {
        type: ActionTypes.GET_PRODUCT_APPL_REQUEST
    }
};

const fetchProductApplSuccess = (status, request) => {
    return {
        type: ActionTypes.GET_PRODUCT_APPL_SUCCESS,
        status,
        request
    }
};

export const fetchProductAppl = (dispatch, token, requestId) => {
    dispatch(fetchProductApplRequest());
    let header = {};
    header["Authorization"] = token;
    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.PRODUCT_REQUEST}/${requestId}`;

    return axios(config)
        .then(response => {
            dispatch(fetchProductApplSuccess(response.data.status, response.data.request))
        })
        .catch(error => {
            throw(error);
        });

}



/*

 PACKAGING UNITS
 */


const fetchPackagingUnitsRequest = () => {
    return {
        type: ActionTypes.LOAD_PACKAGING_UNITS_REQUEST
    }
};

const fetchPackagingUnitsSuccess = (units) => {
    return {
        type: ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS,
        units
    }
};

export const fetchPackagingUnits = (dispatch) => {


    dispatch(fetchPackagingUnitsRequest())
    let header = {};

    header["Accept"] = "application/json";
    header["Content-Type"] = "application/json";

    let config = {};
    config["headers"] = header;
    config["url"] = `${Endpoints.PACKAGING_UNITS}`;

    return axios(config)
        .then(response => {
            dispatch(fetchPackagingUnitsSuccess(response.data.units))
        })
        .catch(error => {

            // if (error.response === undefined) {
            //     dispatch(failure("Error", error.message));
            // } else {
            //     dispatch(failure(error.response.data.error, error.response.data.message));
            // }
            throw(error);

        });

}


const createProductApplRequest = () => {
    return {
        type: ActionTypes.CREATE_PRODUCT_APPL_REQUEST
    }
};

const createProductApplSuccess = (request, status) => {
    return {
        type: ActionTypes.CREATE_PRODUCT_APPL_SUCCESS,
        request,
        status
    }
};

export const createProductAppl = (dispatch, token, info) => {

    return new Promise(function (resolve, reject) {
        dispatch(createProductApplRequest());
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.PRODUCT_REQUESTS}`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(response => {
                dispatch(createProductApplSuccess(response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {
                reject(error)
//                throw(error);
            });

    });
}

const updateRequestRequest = () => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_REQUEST_REQUEST
    }
};

const updateRequestSuccess = (request, status) => {
    return {
        type: ActionTypes.UPDATE_PRODUCT_REQUEST_SUCCESS,
        request,
        status
    }
};

export const updateRequest = (dispatch, token, id, info) => {

    return new Promise(function (resolve, reject) {

        dispatch(updateRequestRequest());
        let header = {}
        header["Authorization"] = token
        header["Accept"] = "application/json"
        header["Content-Type"] = "application/json"

        let config = {}
        config["headers"] = header;
        config["url"] = `${Endpoints.PRODUCT_REQUEST}/${id}/update`;
        config["method"] = "post";
        config["data"] = JSON.stringify(info);

        axios(config)
            .then(response => {
                dispatch(updateRequestSuccess(response.data.request, response.data.status))
                resolve(response);
            })
            .catch(error => {
                reject(error);
            });
    });
}


const uploadProductImageRequest = () => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT_IMAGE_REQUEST
    }
};
const uploadProductImageSuccess = () => {
    return {
        type: ActionTypes.UPLOAD_PRODUCT_IMAGE_SUCCESS
    }
};

export function uploadProductImage(dispatch, id, file, name) {


    dispatch(uploadProductImageRequest())
    let url = `${Endpoints.PRODUCT_IMAGES}/${id}/upload`;
    let data = new FormData();
    data.append('file', file)
    data.append('name', name)

    let config = {};
    config['Content-Type'] = 'multipart/form-data';

    return axios2.post(url, data, {headers: config})
    //return axios(url, data, {headers: config})
        .then(function (response) {

            dispatch(uploadProductImageSuccess())
        })
        .catch(function (error) {

                throw(error);

        });

}


