/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useRef} from "react";
import ProductSenderFragment from "./ProductSenderFragment";
import glnData from "./data/glns.json"
import {removeLeadingZeros} from "./utils/productUtils";
import JsBarcode from "jsbarcode";

const ProductApplGLNView = ({
                         request
                     }) => {
    const containerRef = useRef(null);
    useEffect(() => {
        if (request && request.barcode) {
            let format = null;
            let currBarcode = removeLeadingZeros(request.barcode);
            // if starts with 0 remove all 0
            if (currBarcode.length === 13) {
                format = "EAN13";
            } else if (currBarcode.length === 14) {
                format = "ITF14";
            } else if (currBarcode.length === 8) {
                format = "EAN8";
            }
            if (format) {
                const canvas = document.createElement("canvas");
                canvas.setAttribute("id", "mycanvas")
                let n = {
                    format: format,
                    textAlign: "center",
                    textPosition: "bottom",
                }
                if (containerRef.current.children[0]) {
                    containerRef.current.removeChild(containerRef.current.children[0])
                }
                JsBarcode(canvas, currBarcode, n);
                containerRef.current.appendChild(canvas);
            }
        }
    }, [request]);

    function download(){
        let canvas1 = document.getElementById("mycanvas");
        let url = canvas1.toDataURL("image/png");
        let link = document.createElement('a');
        link.download = request.barcode+'-GLN-'+request.name+'.png';
        link.href = url;
        link.click();
    }

    const locationToString = (a) => {
        let ret = ""
        let ra = glnData.locations.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };
    const locationTypeToString = (a) => {
        let ret = ""
        let ra = glnData.locationTypes.filter(b=>b.id === a);
        if (ra.length>0) {
            ret = ra[0].name;
        }

        return ret
    };
    return (

            <table  className="table-fixed w-full shadow-lg bg-white border-collapse">
                <tbody>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Баркод/GTIN</th>
                    <td className="border px-8 py-4">{request && request.barcode}
                        <div ref={containerRef}>

                        </div>

                        <button onClick={download} className="m-2 p-2 border-2">Download</button>

                    </td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Байршлын нэр</th>
                    <td className="border px-8 py-4">{request && request.name}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Байршил</th>
                    <td className="border px-8 py-4">{request && locationToString(request.location)}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Улс, хот</th>
                    <td className="border px-8 py-4">{request && request.state}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Дүүрэг/аймаг</th>
                    <td className="border px-8 py-4">{request && request.aimak}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Сум/хороо</th>
                    <td className="border px-8 py-4">{request && request.soum}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Утас, факс</th>
                    <td className="border px-8 py-4">{request && request.phone}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">И-мэйл</th>
                    <td className="border px-8 py-4">{request && request.email}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Вэбсайт</th>
                    <td className="border px-8 py-4">{request && request.web}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Сошиал хаяг</th>
                    <td className="border px-8 py-4">{request && request.social}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">ЗИП код</th>
                    <td className="border px-8 py-4">{request && request.zip_code}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Байршлын төрөл</th>
                    <td className="border px-8 py-4">{request && locationTypeToString(request.location_type)}</td>
                </tr>

                <ProductSenderFragment p={request}/>

                </tbody>
            </table>
    )

}


export default ProductApplGLNView

