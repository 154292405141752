/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";
import Resizer from "react-image-file-resizer";
import Compress from "compress.js"
import {useNavigate, useParams} from "react-router-dom";
import {uploadProductImage} from "./actions/actions";
import {toast} from "react-toastify";



const initialState = {
    ofile:{},
    loading: false,
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'PUT_FILES': {
            return {
                ...state,
                ofile:action.ofile,
                openModal: false
            }
        }

        default:
            return state;
    }
};


const putFiles = (ofile) => {
    return {
        type: 'PUT_FILES',
        ofile: ofile
    }
}


const isObjectEmpty = (objectName) => {
    return (
        objectName &&
        Object.keys(objectName).length === 0 &&
        objectName.constructor === Object
    );
};


const ProductImageCreate = ( ) => {
    const {productId} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {ofile} = state;
    const navigate = useNavigate();

    const handleUploadImage = (e) => {
        if (isObjectEmpty(ofile)) {
            alert("File сонгоно уу!")
            return;
        }
        //convert base64 to file
            if (ofile.image) {
                var base64result = ofile.image.split(',')[1];
                const base64str = base64result; //item.image;
                const imgExt = "jpeg"; //img1.ext
                const file = Compress.convertBase64ToFile(base64str, imgExt)
                ofile.file = file
            }
        uploadProductImage(dispatch, productId, ofile.file, ofile.name).then(response => {

                toast.success("Амжилттай !");
                navigate("/image-requests")
            }
        ).catch(error => {
            alert(error)
        });

    };


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1200,
                1200,
                "JPEG",
                75,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });

    const handleFileInputChange = async (e) => {
        if (!e.target.files || e.target.files.length === 0) {
            return
        }
        const file = e.target.files[0]
        const image = await resizeFile(file);
        let v = {}
        v.image = image;
        v.name = file.name;
        v.description = ""

        dispatch(putFiles(v));
    }

    return (
        <div className="m-4">


            <div className="file">
                <label className="file-label">
                    <input className="file-input" type="file" accept="image/png, image/jpeg"  name="resume"
                           onChange={(e) => handleFileInputChange(e, 1)} multiple={false}/>

                </label>
            </div>

            <div className="my-10">
                <img src={ofile.image} width="500" />
            </div>
            <div className="my-4">
                <button className="border-gray-200 p-2 bg-orange-500 text-white" onClick={handleUploadImage}>Upload</button>
            </div>


        </div>

    )

}


export default ProductImageCreate