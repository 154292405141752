/**
 * Created by gantushig on 4/16/17.
 */

import React, {useEffect, useReducer} from "react";

import {createProductAppl, fetchPackagingUnits, fetchProductAppl} from "./actions/actions";
import ProductApplEditComponent from "./ProductApplEditComponent";
import {css} from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";

import {ActionTypes, GLN, GTIN, SSCC, TOKEN} from "./Constants";

import {
    capitalizeFirstLetter,
    checkGlnFieldsFilled,
    checkGtinFieldsFilled,
    checkSsccFieldsFilled,
    createGlnDto,
    createGtinDto,
    createSsccDto
} from "./utils/productUtils";
import {toast} from "react-toastify";
import {useNavigate, useParams} from "react-router-dom";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const initialState = {
    product: {barcode_type:GTIN},
    loading: false,
    units:[]
};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.LOAD_PACKAGING_UNITS_REQUEST: {
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.LOAD_PACKAGING_UNITS_SUCCESS: {
            return {
                ...state,
                units:action.units,
                loading: false
            }
        }
        case ActionTypes.GET_PRODUCT_APPL_REQUEST:{
            return {
                ...state,
                loading: true
            }
        }
        case ActionTypes.GET_PRODUCT_APPL_SUCCESS: {
            return {
                ...state,
                product: action.request,
                loading: false
            }
        }
        case 'CHANGE_PACKAGING': {
            let c = {...state.product};
            c.packaging_unit = action.unit;

            return {
                ...state,
                product: c,
                loading: false
            }
        }
        case 'CHANGE_FIELD':
            let c = {...state.product};
            c[action.name] = action.value
            return {
                ...state,
                product: c,
                loading: false
            }
        default:
            return state;
    }
};


const changeField = (name, v) => {
    let value = capitalizeFirstLetter(v)
    return {
        type: 'CHANGE_FIELD',
        name,
        value
    }
}

const changePackaging = (unit) => {
    return {
        type: 'CHANGE_PACKAGING',
        unit
    }
}
const ProductApplCopy = () => {
    const {requestId} = useParams();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {product, loading, units} = state;

    let token = localStorage.getItem(TOKEN) || null;

    useEffect(() => {
        console.log('useEffect called');
        fetchProductAppl(dispatch, token, requestId);
    }, []);

    useEffect(() => {
        console.log('useEffect fetchPackagingUnits');
        fetchPackagingUnits(dispatch)
    }, []);

    const handleChangePackagingUnit = (e) => {
        dispatch(changePackaging(e.target.value))
    };

    // const handleSave = () => {
    //     if (tempAppl && tempAppl.name
    //         && tempAppl.packaging && tempAppl.constituent && tempAppl.characteristics
    //         && tempAppl.handling && tempAppl.instruction && tempAppl.storage
    //         && tempAppl.sender_name && tempAppl.sender_position && tempAppl.sender_phone && tempAppl.sender_email) {
    //
    //         console.log('save');
    //         let dto = {
    //             barcode: tempAppl.barcode,
    //             name: tempAppl.name,
    //             packaging: tempAppl.packaging,
    //             constituent: tempAppl.constituent,
    //             characteristics: tempAppl.characteristics,
    //             handling: tempAppl.handling,
    //             instruction: tempAppl.instruction,
    //             storage: tempAppl.storage,
    //             sender_name: tempAppl.sender_name,
    //             sender_position: tempAppl.sender_position,
    //             sender_phone: tempAppl.sender_phone,
    //             sender_email: tempAppl.sender_email
    //         };
    //         reduxDispatch(createProductAppl(token, dto))
    //         setSendClicked(true)
    //     } else {
    //         alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
    //     }
    // };

    const handleSave = () => {
        let checkFailed = false;
        if (
            product.barcode_type === GTIN  ) {
            if (!checkGtinFieldsFilled(product)) {
                console.log('save check gtin');
                checkFailed = true;
            }
        } else if (product.barcode_type === GLN) {
            if (!checkGlnFieldsFilled(product)) {
                checkFailed = true;
            }
        } else if (product.barcode_type === SSCC) {
            if (!checkSsccFieldsFilled(product)) {
                checkFailed = true;
            }
        }
        if (checkFailed) {
            console.log('save check gtin');
            alert("Та одоор * тэмдэглэсэн заавал оруулах талбаруудыг оруулна уу!")
            return;
        }

        let dto = {}
        if (product.barcode_type === GTIN) {
            dto = createGtinDto(product)
        } else if (product.barcode_type === GLN) {
            dto = createGlnDto(product)
        } else if (product.barcode_type === SSCC) {
            dto = createSsccDto(product)
        }
        createProductAppl(dispatch, token, dto).then(response => {
                toast.success("Амжилттай илгээлээ!");
            navigate("/requests")
            }
        ).catch(error => {
            alert(error)
        })
    };
    const navigate = useNavigate();
    const handleCancel = () => {
        console.log('cancel');
        navigate(-1)
    };
    const handleChangeField =(e)=> {
        dispatch(changeField(e.target.name, e.target.value))
    }

    return (
        <div className="m-4">
            <h2 className="text-2xl font-bold m-2">Бүтээгдэхүүний анкет</h2>
            <hr/>
            <ul className="list-disc ml-6 my-5">
                <li>
                    Монголоор бичнэ. / Галигаар бичихгүй /
                </li>
                <li>
                    Одтой хэсгийг заавал бөглөнө.
                </li>
                <li>
                    Бүх хүсэлтээ илгээж дуусаад заавал гарах эсвэл refresh товчоо заавал дарсан байх.
                </li>
                <li>
                    Зураасан код орсны дараа эргэж нэвтэрч ороод GCP хэсгээс файлаар татаж авна.
                </li>
            </ul>
            <ProductApplEditComponent
                product={product}
                onChangeField={handleChangeField}
                onSave={handleSave} onCancel={handleCancel}
                packaging_units={units} onChangePackagingUnit={handleChangePackagingUnit}/>

        </div>
    )
}


export default ProductApplCopy