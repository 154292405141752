/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Endpoints, GLN, GTIN, SSCC} from './Constants';
import ProductApplGTINView from "./ProductApplGTINView";
import ProductApplGLNView from "./ProductApplGLNView";
import ProductApplSSCCView from "./ProductApplSSCCView";


import {css} from "@emotion/react";
import axios from "./axiosConfig";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const ProductApplView = () => {

    const {requestId} = useParams();
    const [data, setData] = useState({request:{barcode_type:null}});

    useEffect(() => {
        async function getReq() {
            const response = await axios.get(`${Endpoints.PRODUCT_REQUEST}/${requestId}`);
            setData(response.data);
        }
        getReq();

    }, []);

    if (data.request.barcode_type === null) {
        data.request.barcode_type = "GTIN";
    }

    return (
        <div className="m-4">
            <div  className="my-4">
                <Link to={`/request/${data.request.id}/copy`} className="border p-2 rounded-md  border-orange-500" type="button">
                    Хуулах
                </Link>
                <Link to={`/request/${data.request.id}/edit`} className="border p-2 rounded-md ml-2 border-orange-500" type="button">
                    Өөрчлөх
                </Link>
            </div>

            {data.request.status === "DENIED" &&
            <div style={{border: "1px solid red", padding: "12px", marginBottom: "10px"}}>
                <b>Төлөв:</b> Буцсан<br/>
                <b>Шалтгаан:</b> {data.request.note}
            </div>

            }
            {/*<ClipLoader color="#ffffff" loading={loading} css={override} size={150}/>*/}

            {data.request.barcode_type === GTIN &&
            <ProductApplGTINView request={data.request}/>
            }
            {data.request.barcode_type === GLN &&
            <ProductApplGLNView request={data.request}/>
            }
            {data.request.barcode_type === SSCC &&
            <ProductApplSSCCView request={data.request}/>
            }
            <hr/>


        </div>
    )

}


export default ProductApplView

