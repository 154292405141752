function checkGtinFieldsFilled  (p) {
    return !!(p.name && p.brand_name
        && p.packaging && p.packaging_unit && p.packaging_unit !=="0" && p.constituent && p.characteristics
        && p.handling && p.instruction && p.storage && p.coo && p.exp_countries_member && p.image_link
        && p.sender_name && p.sender_position && p.sender_phone && p.sender_email
    );
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function createGtinDto  (p) {
    return {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        name: p.name,
        brand_name:p.brand_name,
        packaging: p.packaging,
        packaging_unit:p.packaging_unit,
        constituent: p.constituent,
        characteristics: p.characteristics,
        handling: p.handling,
        instruction: p.instruction,
        storage: p.storage,
        coo: p.coo,
        exp_countries_member:p.exp_countries_member,
        image_link:p.image_link,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };
}

function checkGlnFieldsFilled  (p) {
    const ret =
        (p.name  && p.location && p.location_type
            && p.state && p.aimak && p.soum
            && p.email && p.web && p.social && p.zip_code
            && p.sender_name && p.sender_position && p.sender_phone && p.sender_email) ? true:false;

    return ret;
}

function createGlnDto  (p) {
    return {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        location: p.location,
        location_type: p.location_type,
        state: p.state,
        aimak: p.aimak,
        soum: p.soum,
        phone: p.phone,
        email: p.email,
        web: p.web,
        social: p.social,
        zip_code: p.zip_code,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };
}


function checkSsccFieldsFilled  (p) {
    let ret;
    ret = !!(p.name
        && p.unit_weight && p.exporting_country && p.package_quantity && p.pallet_quantity
        && p.sender_name && p.sender_position && p.sender_phone && p.sender_email);

    return ret;
}


function createSsccDto  (p) {
    return  {
        barcode_type: p.barcode_type,
        barcode: p.barcode,
        name: p.name,
        unit_weight: p.unit_weight,
        exporting_country: p.exporting_country,
        package_quantity: p.package_quantity,
        pallet_quantity: p.pallet_quantity,
        sender_name: p.sender_name,
        sender_position: p.sender_position,
        sender_phone: p.sender_phone,
        sender_email: p.sender_email
    };

    //return dto;
}

const removeLeadingZeros = (input)=> {
    let result = input;
    while (result[0] === '0') {
        result = result.substring(1);
    }
    return result;
}

export {checkGtinFieldsFilled, createGtinDto, checkGlnFieldsFilled, createGlnDto,
    checkSsccFieldsFilled,createSsccDto,capitalizeFirstLetter,
removeLeadingZeros};