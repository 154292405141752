import {
    Routes,
    Route,
    NavLink,
    Navigate,
    useNavigate,
    useLocation,
} from 'react-router-dom';
import {useAuth} from "./useAuth";


export const ProtectedRoute = ({ children }) => {
    const { isAuthenticated } = useAuth();
    const location = useLocation();

    if (!isAuthenticated) {
            return <Navigate to="/login" replace state={{ from: location }} />;

    }

    return children;
};