/**
 * Created by gantushig on 11/6/15.
 */

import React from "react";

const ProductApplExpCountriesFragment = ({
                             p
                         }) => {

    const getExpCountriesLabel = (p)=>{
        let ret = "";
        if (p.exp_countries) {

            p.exp_countries.map(el => {
                ret += el.name;
                ret += "/" + el.id;
                ret += ", ";
            })
            if (ret !== "") {
                ret = ret.replace(/,\s*$/, "");
            }
        }
        return ret;
    }

    return (
        <>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс</th>
                <td className="border px-8 py-4">{p && p.exp_countries_member}</td>
            </tr>

        </>
    )

}


export default ProductApplExpCountriesFragment

