import React from "react";
import {format, parseISO} from 'date-fns'
import aimaksData from "../data/aimaks.json"


const CompanyViewComponent = ({company, contacts, onDownloadDoc}) => {
    //
    // const aimakToString = (a) => {
    //     let ret = ""
    //     let ra = aimaksData.filter(b=>b.id === a);
    //     if (ra.length>0) {
    //         ret = ra[0].name;
    //     }
    //
    //     return ret
    // };
    //
    // const districtToString = (aimak, d) => {
    //     let ret = ""
    //     if (aimak) {
    //         let ra = aimaksData.filter(b=>b.id === aimak);
    //         if (ra.length>0) {
    //             let sa  = ra[0].units.filter(c=>c.id === d);
    //             if (sa.length > 0) {
    //                 ret = sa[0].name;
    //             }
    //         }
    //     }
    //     return ret
    //
    // };

    const showGender = (g) => {
        let ret = "";
        if (g === "MALE") {
            ret = "Ноён";
        } else if ( g === "FEMALE") {
            ret = "Хатагтай";
        }

        return ret
    };
    const showPositionType = (g) => {
        let ret = "Бусад";
        if (g === "CEO") {
            ret = "Захирал";
        } else if ( g === "GS1") {
            ret = "Код хариуцсан ажилтан";
        } else if ( g === "ACC") {
            ret = "Нябо";
        }

        return ret
    };
    const items = contacts.map(
        (p) => {
            return (
                <tr key={p.id} style={{marginBottom:'10px'}}>
                    <td className="border px-8 py-4">{p.counter}</td>
                    <td className="border px-8 py-4">{showGender(p.gender)} {p.surname} {p.name}</td>
                    <td className="border px-8 py-4">{showPositionType(p.position_type)}  / {p.position!==null ? ( p.position ): ""}</td>
                    <td className="border px-8 py-4">{p.phone} {p.fax !== "" && <span>Факс: {p.fax}</span>}</td>
                    <td className="border px-8 py-4"> {p.email}</td>
                </tr>
            )
        });


    return (
        <div className="m-4">
            {/*<h2 className="text-2xl font-bold">Байгууллага</h2>*/}
            <table className="w-full shadow-lg bg-white border-collapse">
                <tbody>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэр</th>
                    <td className="border px-8 py-4">{company && company.name}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэр Англиар</th>
                    <td className="border px-8 py-4">{company && company.name_english}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Улсын бүртгэлийн дугаар</th>
                    <td className="border px-8 py-4">{company && company.state_reg_number}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Регистрийн дугаар</th>
                    <td className="border px-8 py-4">{company && company.registration_number}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Байгуулагдсан огноо</th>
                    <td className="border px-8 py-4">{company && company.established &&
                        format(parseISO(company.established), "yyyy-MM-dd")

                    }</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Элссэн огноо</th>
                    <td className="border px-8 py-4">{company && company.joined &&
                        format(parseISO(company.joined), "yyyy-MM-dd")

                    }</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Аймаг/Хот</th>
                    <td className="border px-8 py-4">{company && company.aimak_str}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Дүүрэг</th>
                    <td className="border px-8 py-4">{company && company.district_str}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Сум/Хороо</th>
                    <td className="border px-8 py-4">{company && company.soum}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Гудамж/Тоот</th>
                    <td className="border px-8 py-4">{company && company.address}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Утас</th>
                    <td className="border px-8 py-4">{company && company.phone}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Шуудангийн хаяг</th>
                    <td className="border px-8 py-4">{company && company.postal_address}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Мэйл</th>
                    <td className="border px-8 py-4">{company && company.email}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Сошиал хаяг</th>
                    <td className="border px-8 py-4"> {company && company.social}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Вебхуудас</th>
                    <td className="border px-8 py-4">{company && company.web}</td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Үйл ажиллагааны чиглэл</th>
                    <td className="border px-8 py-4">{company && company.sector_name}</td>
                </tr>

                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Албан Тоот</th>
                    <td className="border px-8 py-4">
                        { company && company.exists_letter_att &&
                        <div>
                            <button className="text-blue-500 underline" onClick={(e) => onDownloadDoc(1)}>{company.letter_file_name}</button>

                        </div>
                        }
                    </td>
                </tr>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Гэрчилгээ/Үнэмлэх</th>
                    <td className="border px-8 py-4">
                        { company && company.exists_doc_att &&
                        <div>
                            <button className="text-blue-500 underline" onClick={(e) => onDownloadDoc(2)}>{company.doc_file_name}</button>
                        </div>
                        }

                    </td>
                </tr>

                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">Гэрчилгээ/Үнэмлэх / Арын Хуудас/</th>
                    <td className="border px-8 py-4">
                        { company && company.exists_doc2_att &&
                        <div>
                            <button className="btn btn-link" onClick={(e) => onDownloadDoc(3)}>{company.doc2_file_name}</button>
                        </div>
                        }

                    </td>
                </tr>
                <tr>
                    <th  className="bg-blue-100 border text-left px-8 py-4">GEPIR</th>
                    <td  className="border px-8 py-4"><input type="checkbox" disabled
                                                         checked={company && company.is_gepir}/></td>
                </tr>
                </tbody>
            </table>
            <br/>
            <h2 className="text-2xl font-bold">Хариуцсан ажилтан</h2>
            <br/>
            <table className="shadow-lg bg-white border-collapse w-full">
                <thead>
                <tr>
                    <th className="bg-blue-100 border text-left px-8 py-4">#</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Нэр</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Албан Тушаал</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Утас</th>
                    <th className="bg-blue-100 border text-left px-8 py-4">Мэйл</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>
            <br/><br/>
        </div>
    )

}


export default CompanyViewComponent




