/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useState} from "react";

import {Endpoints} from "./Constants";
import axios from "./axiosConfig";
import axios2 from "./axiosConfig2";
import {useParams} from "react-router-dom";
import FileDownload from "js-file-download";


const InvoiceView = () => {
    //{token, invoice, gcpId, invoiceId, loadInvoice, print, invoiceItems}
    let {gcpId, invoiceId} = useParams();
    const [data, setData] = useState(null);

    useEffect(() => {
        async function getInvoice() {
            const response = await axios.get(`${Endpoints.INVOICE_ADMIN}/${invoiceId}`);
            setData(response.data);
        }

        getInvoice();

    }, []);


    // useEffect(() => {
    //     console.log('useEffect called');
    //
    //
    //     loadInvoice(token, invoiceId)
    // }, []);

    // if (invoice === null || invoice === undefined) {
    //     return (
    //         <div className="p-2" style={{backgroundColor: "white"}}>
    //             <div className="level">
    //                 <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
    //                     <a className="button is-outline is-success" onClick={() => handlePrint()}>
    //                         Print
    //                     </a>
    //                 </div>
    //             </div>
    //             <table className="table is-bordered is-fullwidth">
    //                 <thead>
    //                 <tr>
    //                     <th>#</th>
    //                     <th>Жил</th>
    //                     <th>Дүн</th>
    //                 </tr>
    //                 </thead>
    //                 <tbody>
    //                 </tbody>
    //             </table>
    //             <div className="subtitle">No Items</div>
    //
    //         </div>
    //     )
    // }
    // ;

    const handlePrint = () => {
        console.log('print invoice');

        async function printInvoice() {
            let config = {};
            config["url"] = `${Endpoints.INVOICE_ADMIN}/${invoiceId}/print`;
            config["responseType"] = "arraybuffer";

            let response = await axios2(config);
            const fileNameHeader = "x-suggested-filename";
            const suggestedFileName = response.headers[fileNameHeader]
            let fn = decodeURIComponent(suggestedFileName);

            FileDownload(response.data, fn)
        }
        printInvoice();
        //print(token, invoiceId)
    };


    let counter = 0;
    const items = data.items.map(
        (item) => {
            counter++;
            return (
                <tr key={item.id}>
                    <td>{counter}</td>
                    <td>{item.year}</td>
                    <td>{item.amount.toLocaleString()}₮</td>
                </tr>
            )
        });

    if (data.invoice === null || data.invoice === undefined || data.invoice.total_amount === undefined) {
    } else {
        data.invoice.total_amount = data.invoice.total_amount.toLocaleString()
    }

    return (
        <div className="m-4" >
            <div className="level">
                <div className="level-left" style={{paddingLeft: '10px', paddingTop: '10px'}}>
                    <a className="button is-outline is-success" onClick={() => handlePrint()}>
                        Print
                    </a>
                </div>
            </div>
            <table className="table">
                <thead>
                <tr>
                    <th>#</th>
                    <th>Жил</th>
                    <th>Дүн</th>
                </tr>
                </thead>
                <tbody>
                {items}
                </tbody>
            </table>

            НӨАТ /10%/: {data.invoice.vat_amount}₮
            Нийт: {data.invoice.total_amount}₮
            <br/><br/>

        </div>
    )

}

//
// const mapStateToProps = (state, ownProps) => {
//     let isUpdating = state.ui.isUpdating;
//     let token = localStorage.getItem(TOKEN) || null;
//
//     const gcpId = ownProps.match.params.gcpId
//     const invoiceId = ownProps.match.params.invoiceId
//     let invoice = state.invoices.invoice;
//     let invoiceItems = state.invoices.items;
//     let counter = 0
//
//     if (invoice === null || invoice === undefined || invoice.total_amount === undefined) {
//     } else {
//         invoice.total_amount = invoice.total_amount.toLocaleString()
//     }
//
//     invoiceItems.map(
//         (item) => {
//             counter++
//             item.counter = counter
//             item.amount = item.amount.toLocaleString()
//         });
//
//
//     return {
//         token,
//         isUpdating,
//         invoice,
//         invoiceId,
//         gcpId,
//         invoiceItems
//     }
// }
//
// const mapDispatchToProps = (dispatch) => {
//     return {
//
//         loadInvoice: (token, invoiceId) => {
//             dispatch(fetchInvoice(token, invoiceId))
//         },
//         print: (token, id) => {
//             dispatch(printInvoice(token, id))
//         },
//
//     }
// };


export default InvoiceView; //connect(mapStateToProps, mapDispatchToProps)(InvoiceView)


