/**
 * Created by gantushig on 11/6/15.
 */

//import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {Endpoints, TOKEN} from './Constants';
import ProductApplGLNView from "./ProductApplGLNView";
import ProductApplSSCCView from "./ProductApplSSCCView";
import ProductGTINView from "./ProductGTINView";
import axios from "./axiosConfig";
import {Link, useParams} from "react-router-dom";
import axios2 from "./axiosConfig2";
import {Buffer} from 'buffer';

const ProductView = () => {
    let {productId} = useParams();
    const [data, setData] = useState({product:{exists_image_1:false,exists_image_2:false, exists_image_3:false}});
    const [image1, setImage1] = useState(null);
    const [image2, setImage2] = useState(null);
    const [image3, setImage3] = useState(null);

    useEffect(() => {
        async function getProduct() {
            const response = await axios.get(`${Endpoints.PRODUCT}/${productId}`);
            setData(response.data);
        }
        getProduct();

    }, []);


    async function fetchImage(id, side) {
        let config = {};
        config["url"] = `${Endpoints.PRODUCT}/${id}/image/${side}`;
        config["responseType"] = "arraybuffer";

        let response = await axios2(config);
        let prefix = "data:" + response.headers["content-type"] + ";base64,";  //"data:image/png;base64,"; //
        let img = Buffer.from(response.data, "binary").toString("base64");
        if (side === 1) {
            setImage1(prefix + img);
        } else  if (side === 2) {
            setImage2(prefix + img);
        } else  if (side === 3) {
            setImage3(prefix + img);
        }
    }

    useEffect(() => {
        if (data.product.exists_image_1) {
            fetchImage(productId, 1);
        }
    }, [data.product.exists_image_1]);
    useEffect(() => {
        if (data.product.exists_image_2) {
            fetchImage(productId, 2);
        }
    }, [data.product.exists_image_2]);
    useEffect(() => {
        if (data.product.exists_image_3) {
            fetchImage(productId, 3);
        }
    }, [data.product.exists_image_3]);



    return (
        <div className="m-4">

            <h2 className="font-bold text-xl p-2">{data.product.barcode} - {data.product.name}</h2>

            {(data.product.barcode_type === null || data.product.barcode_type === "GTIN") &&
            <ProductGTINView p={data.product} image1={image1}  image2={image2}  image3={image3}/>
            }
            {data.product.barcode_type === "GLN" &&
            <ProductApplGLNView request={data.product}/>
            }
            {data.product.barcode_type === "SSCC" &&
            <ProductApplSSCCView request={data.product}/>
            }

            <hr/>

            <div className="flex justify-end mt-4 mb-11">
            <Link className="border border-gray-300 p-4 bg-orange-500 text-white" to={`/product/${productId}/image`}>Зураг оруулах хүсэлт илгээх</Link>
            </div>


        </div>
    )

}


export default ProductView;

