import './App.css';
import {AuthProvider} from "./AuthProvider";
import {useAuth} from "./useAuth";
import {Link, Navigate, NavLink, Route, Routes, useLocation, useNavigate} from "react-router-dom";
import {ProtectedRoute} from "./ProtectedRoute";
import React, {useEffect, useState} from "react";
import Dashboard from "./Dashboard";
import LoginEmail from "./LoginEmail";
import CompanyView from "./CompanyView";
import InvoicePayView from "./InvoicePayView";
import CompanyInvoices from "./CompanyInvoices";
import CompanyPayments from "./CompanyPayments";
import BarcodesPage from "./BarcodesPage";
import ProductApplCopy from "./ProductApplCopy";
import ProductApplEdit from "./ProductApplEdit";
import ProductApplView from "./ProductApplView";
import ProductApplCreateQuestion from "./ProductApplCreateQuestion";
import ProductApplCreate from "./ProductApplCreate";
import CompanyRequests from "./CompanyRequests";
import CompanyProducts from "./CompanyProducts";
import InvoiceView from "./InvoiceView";
import ProductView from "./ProductView";
import {Endpoints, LOGGED_IN} from "./Constants";
import axios from "./axiosConfig";
import CompanyGcps from "./CompanyGcps";
import logo from "./images/gs1mn-logo2.png";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import packageJson from "../package.json";
import ProductImageCreate from "./ProductImageCreate";
import CompanyImageRequests from "./CompanyImageRequests";
import ProductImageView from "./ProductImageView";

function App() {
    const navigate = useNavigate();
    const { isAuthenticated, onLogout} = useAuth();
    const location = useLocation();
    const {pathname} = location;
    const checkForInactivity = () => {
        const expireTime = localStorage.getItem("expireTime");
        if (expireTime < Date.now()) {
            const loggedIn = localStorage.getItem(LOGGED_IN);
            if (loggedIn) {
                console.log("Logout");
                onLogout();
                navigate("/login");
            }
        }
    }

    //function to update expire time
    const updateExpireTime = () => {

        // expire time is 15 minutes
        const expireTime = Date.now() + 1000 * 60 * 15;
        localStorage.setItem("expireTime", expireTime);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            checkForInactivity();

            // check every 30 seconds
        }, 1000*30)
        // cleanup
        return () => {
            clearInterval(interval);
        };
    }, []);

    useEffect(() => {

        // initiate timeout
        updateExpireTime();

        // listen for mouse events
        window.addEventListener('mousemove', updateExpireTime);
        window.addEventListener('scroll', updateExpireTime);
        window.addEventListener('keydown', updateExpireTime);

        // cleanup
        return () => {
            window.removeEventListener('mousemove', updateExpireTime);
            window.removeEventListener('scroll', updateExpireTime);
            window.removeEventListener('keydown', updateExpireTime);
        };
    }, []);

    // async function logout() {
    //     const resp = await axios.get(Endpoints.LOGOUTV1_URL)
    //     localStorage.removeItem(LOGGED_IN)
    // }

    return (
            <div className="bg-gray-200">
                <div className="container mx-auto bg-white">
                    <ToastContainer/>
                    {pathname === '/invoices' ? <NavigationInvoice/> : <Navigation/>}

                    <Routes>
                        <Route index element={<Home/>}/>
                        <Route path="home" element={<Home/>}/>
                        <Route path="login" element={<LoginEmail/>}/>
                        <Route
                            path="dashboard"
                            element={
                                <ProtectedRoute>
                                    <Dashboard/>
                                </ProtectedRoute>
                            }
                        />

                        <Route
                            path="company"
                            element={
                                <ProtectedRoute>
                                    <CompanyView/>
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="invoices"
                            element={
                                <ProtectedRoute>
                                    <InvoicePayView/>
                                </ProtectedRoute>
                            }
                        />
                        <Route path="/gcps"
                               element={
                                   <ProtectedRoute>
                                       <CompanyGcps/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/products"
                               element={
                                   <ProtectedRoute>
                                       <CompanyProducts/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/requests"
                               element={
                                   <ProtectedRoute>
                                       <CompanyRequests/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/image-requests"
                               element={
                                   <ProtectedRoute>
                                       <CompanyImageRequests/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/image-request/:requestId"
                               element={
                                   <ProtectedRoute>
                                       <ProductImageView/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/product/:productId"
                               element={
                                   <ProtectedRoute>
                                       <ProductView/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/request-create/:tp?"
                               element={
                                   <ProtectedRoute>
                                       <ProductApplCreate/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/request-create-question/:tp?"
                               element={
                                   <ProtectedRoute>
                                       <ProductApplCreateQuestion/>
                                   </ProtectedRoute>
                               }

                        />
                        <Route path="/request/:requestId"
                               element={
                                   <ProtectedRoute>
                                       <ProductApplView/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/request/:requestId/edit"
                               element={
                                   <ProtectedRoute>
                                       <ProductApplEdit/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/request/:requestId/copy"
                               element={
                                   <ProtectedRoute>
                                       <ProductApplCopy/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/gcp/:gcpId/barcodes"
                               element={
                                   <ProtectedRoute>
                                       <BarcodesPage/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/gcp/:gcpId/payments"
                               element={
                                   <ProtectedRoute>
                                       <CompanyPayments/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/gcp/:gcpId/invoices"
                               element={
                                   <ProtectedRoute>
                                       <CompanyInvoices/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/gcp/:gcpId/invoice/:invoiceId"
                               element={
                                   <ProtectedRoute>
                                       <InvoiceView/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="/product/:productId/image"
                               element={
                                   <ProtectedRoute>
                                       <ProductImageCreate/>
                                   </ProtectedRoute>
                               }
                        />
                        <Route path="*" element={<NoMatch/>}/>
                    </Routes>
                    <footer>
                        <div className="container">
                            <div className="p-2" style={{backgroundColor: "grey", color: "white"}}>
                                <p> Copyright &copy; 2020-{new Date().getFullYear()} GS1 Монгол ТББ. The Global Language
                                    Of Business,
                                    Version {packageJson.version}</p>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        // </AuthProvider>
    );
}

const Navigation = () => {
    const {isAuthenticated, onLogout} = useAuth();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const handleMobileMenuClick = () => {
        setShowMobileMenu(!showMobileMenu);
    }
    if (isAuthenticated) {
        return (
            <header>
                <div className="max-w-full mx-auto px-4 py-4 bg-gray-100 hidden md:block">
                    <div className="flex flex-row justify-start">
                        <img src={logo}/>
                        <p className="ml-2 pt-2">
                            <strong>Welcome to GS1 Mongolia</strong><br/>The Global Language of Business
                        </p>
                    </div>
                </div>
                <nav className="bg-white shadow-lg">
                    <div className="max-w-6xl mx-auto px-4">
                        <div className="flex justify-between">
                            <div className="flex space-x-7">
                                {/*Primary Navbar items*/}
                                <div className="hidden md:flex items-center space-x-1">
                                    <NavLink to="/company" className={({isActive}) =>
                                        isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>Байгууллага</NavLink>
                                    <NavLink to="/gcps" className={({isActive}) =>
                                        isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>GCP</NavLink>
                                    <NavLink to="/products"
                                             className={({isActive}) =>
                                                 isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>Бүтээгдэхүүнүүд</NavLink>
                                    <NavLink to="/requests"
                                             className={({isActive}) =>
                                                 isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>Илгээсэн
                                        Хүсэлтүүд</NavLink>
                                    <NavLink to="/request-create-question/GLN"
                                             className={({isActive}) =>
                                                 isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>GLN</NavLink>
                                    <NavLink to="/request-create-question/SSCC"
                                             className={({isActive}) =>
                                                 isActive ? "py-4 px-2 text-orange-500 border-b-4 border-orange-500 font-semibold" : "py-4 px-2 text-gray-500 font-semibold hover:text-orange-500 transition duration-300"}
                                             end>SSCC</NavLink>
                                </div>

                                {/*Secondary Navbar items*/}
                                <div className="hidden md:flex items-center space-x-3">
                                    <Link to="/request-create"
                                          className="py-2 px-2 font-medium text-white bg-orange-500 rounded hover:bg-orange-400 transition duration-300">
                                        Зураасан кодны хүсэлт
                                    </Link>
                                    <button
                                        className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                                        onClick={onLogout}>
                                        Гарах
                                    </button>
                                </div>


                            </div>
                        </div>
                        {/*Mobile menu button */}
                        <div className="md:hidden flex justify-between h-12">
                            <h2 className="font-bold text-xl my-auto">GS1 Mongolia</h2>
                            <button className="outline-none mobile-menu-button" onClick={handleMobileMenuClick}>
                                <svg className=" w-6 h-6 text-gray-500 hover:text-orange-500 "
                                     x-show="!showMenu"
                                     fill="none"
                                     strokeLinecap="round"
                                     strokeLinejoin="round"
                                     strokeWidth="2"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor"
                                >
                                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                        </div>
                        {/*mobile menu*/}
                        {showMobileMenu ?
                            <div className="">
                                <ul className="">
                                    <li><NavLink to="/company" onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end
                                    >Байгууллага</NavLink>
                                    </li>
                                    <li><NavLink to="/gcps"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end
                                    >GCP</NavLink>
                                    </li>
                                    <li><NavLink to="/products"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end>Бүтээгдэхүүнүүд</NavLink>
                                    </li>
                                    <li><NavLink to="/requests"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end>Илгээсэн
                                        Хүсэлтүүд</NavLink></li>
                                    <li><NavLink to="/request-create-question/GLN"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end>GLN</NavLink></li>
                                    <li><NavLink to="/request-create-question/SSCC"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end>
                                        SSCC</NavLink></li>
                                    <li><NavLink to="/request-create"  onClick={handleMobileMenuClick}
                                                 className={({isActive}) =>
                                                     isActive ? "block text-sm px-2 py-4 text-white bg-orange-500 font-semibold" : "block text-sm px-2 py-4 hover:bg-green-500 transition duration-300"}
                                                 end>Зураасан кодны хүсэлт</NavLink></li>
                                    <li>
                                        <button
                                            className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                                            onClick={onLogout}>
                                            Гарах
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            : null}
                    </div>

                </nav>
            </header>
        );
    }

};


const NavigationInvoice = () => {
    const {isAuthenticated, onLogout} = useAuth();
    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const handleMobileMenuClick = () => {
        setShowMobileMenu(!showMobileMenu);
    }
    if (isAuthenticated) {
        return (
            <header>
                <div className="max-w-full mx-auto px-4 py-4 bg-gray-100 hidden md:block">
                    <div className="flex flex-row justify-start">
                        <img src={logo}/>
                        <p className="ml-2 pt-2">
                            <strong>Welcome to GS1 Mongolia</strong><br/>The Global Language of Business
                        </p>
                    </div>
                </div>
                <nav className="bg-white shadow-lg">
                    <div className="max-w-6xl mx-auto px-4">
                        <div className="flex justify-end">
                            <div className="flex space-x-7">

                                {/*Secondary Navbar items*/}
                                <div className="hidden md:flex items-center space-x-3">
                                    <button
                                        className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                                        onClick={onLogout}>
                                        Гарах
                                    </button>
                                </div>


                            </div>
                        </div>
                        {/*Mobile menu button */}
                        <div className="md:hidden flex justify-between h-12">
                            <h2 className="font-bold text-xl my-auto">GS1 Mongolia</h2>
                            <button className="outline-none mobile-menu-button" onClick={handleMobileMenuClick}>
                                <svg className=" w-6 h-6 text-gray-500 hover:text-orange-500 "
                                     x-show="!showMenu"
                                     fill="none"
                                     stroke-linecap="round"
                                     stroke-linejoin="round"
                                     stroke-width="2"
                                     viewBox="0 0 24 24"
                                     stroke="currentColor"
                                >
                                    <path d="M4 6h16M4 12h16M4 18h16"></path>
                                </svg>
                            </button>
                        </div>
                        {/*mobile menu*/}
                        {showMobileMenu ?
                            <div className="">
                                <ul className="">
                                    <li>
                                        <button
                                            className="py-2 px-2 font-medium text-gray-500 rounded hover:bg-green-500 hover:text-white transition duration-300"
                                            onClick={onLogout}>
                                            Гарах
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            : null}
                    </div>

                </nav>
            </header>
        );
    }

};

const NoMatch = () => {
    const {isAuthenticated} = useAuth();
    if (isAuthenticated) {
        return (
            <>
                <h2 className="text-3xl font-bold text-center my-6">No Match</h2>
            </>
        );
    } else {
        return (
            <>
                <div className="max-w-full mx-auto px-4 py-4 bg-gray-100 hidden md:block">
                    <div className="flex flex-row justify-start">
                        <img src={logo}/>
                        <p className="ml-2 pt-2">
                            <strong>Welcome to GS1 Mongolia</strong><br/>The Global Language of Business
                        </p>
                    </div>

                </div>
                <h2 className="text-3xl font-bold text-center my-6">No Match</h2>
            </>
        );
    }
};


const Home = () => {
    const navigate = useNavigate()
    const {isAuthenticated} = useAuth();
    useEffect(() => {
        // Checking if user is not loggedIn
        if (isAuthenticated) {
            navigate("/company");
        } else {
            navigate("/login");
        }
    }, [navigate, isAuthenticated]);

    return (<></>)
 };

export default App;
