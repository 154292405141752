/**
 * Created by gantushig on 4/10/20.
 */
import React from "react";
import { format,  parseISO } from 'date-fns'
import "../scss/InvoicePayView.scss"

const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
};

export default ({invoice}) => {
    return (

        <div className="inv_container" key={invoice.id}>
            <div className="inv_number">
                <p><strong>НЭХЭМЖЛЭХ №{invoice.id}</strong></p>
            </div>
            <div className="inv_title">
                <p>"Дэлхийн стандарт нэг монгол нийгэмлэг" ТББ</p>
            </div>
            <div className="inv_address_container">
                <div className="inv_address">
                    <strong>Хаяг:</strong> "Морьтон" цогцолбор,
                    9-р давхар<br/>
                    Хан-Уул дүүрэг, 3-р хороо<br/>
                    Утас: (976) 77 000 865<br/>
                    Регистрийн дугаар: 8218528
                </div>
                <div className="inv_receiver">
                    <strong>{invoice.company_name}</strong><br/>
                    <small className="note_rec">........../ Төлөгч байгууллагын нэр /......</small>
                </div>
            </div>
            <div className="inv_acc_name">
                <strong>Дансны дугаар:</strong> ХААН 562 2272 133 /MNT/ <strong>Хүлээн авагч:</strong> Дэлхийн стандарт нэг монгол нийгэмлэг<br/>
                <small className="note">( гүйлгээний утга дээрээ байгууллагын нэр, регистр болон утасны дугаараа бичнэ
                    үү)</small>
            </div>
            <table className="min-w-full border text-center text-sm font-light dark:border-neutral-500 inv_table">
                <thead className="border-b font-medium dark:border-neutral-500">
                <tr>
                    <th className="text-left border-r px-6 py-3">#</th>
                    <th className="text-left border-r px-6 py-3">Гүйлгээний утга</th>
                    <th className="text-left px-6 py-3">Дүн</th>
                </tr>
                </thead>
                <tbody>
                <tr className="border-b dark:border-neutral-500">
                    <td className="text-left whitespace-nowrap border-r px-6 py-4 font-medium dark:border-neutral-500">{1}</td>
                    <td className="text-left whitespace-nowrap border-r px-6 py-4 font-medium dark:border-neutral-500">Зур. кодны сунгалт {invoice.label}</td>
                    <td className="text-left whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500">{invoice.total_amount.toLocaleString('en', options)} ₮</td>
                </tr>
                <tr >
                    <td colSpan={3}></td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                    <td colSpan={2}  className="text-left whitespace-nowrap border-r px-6 py-4 font-medium dark:border-neutral-500">Нийт дүн:</td>
                    <td  className="text-left whitespace-nowrap  px-6 py-4 font-medium dark:border-neutral-500">{invoice.total_amount.toLocaleString('en', options)} ₮</td>
                </tr>
                <tr className="border-b dark:border-neutral-500">
                    <td  className="text-left whitespace-nowrap px-6 py-4 font-medium dark:border-neutral-500"colSpan={3}>Үсгээр: {invoice.total_word} төгрөг</td>
                </tr>
                </tbody>
            </table>
            <div className="inv_footer">
                <p>Нягтлан бодогч............................................../”GS1 Mongolia”/
                    &nbsp;{format(parseISO(invoice.created), "yyyy.MM.dd")}</p>
            </div>
        </div>
    )
};
