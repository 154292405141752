/**
 * Created by gantushig on 11/6/15.
 */

import {Link, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {Endpoints, GLN, GTIN, SSCC} from './Constants';
import ProductApplGTINView from "./ProductApplGTINView";
import ProductApplGLNView from "./ProductApplGLNView";
import ProductApplSSCCView from "./ProductApplSSCCView";


import {css} from "@emotion/react";
import axios from "./axiosConfig";
import qs from "query-string";
import axios2 from "./axiosConfig2";
import {Buffer} from "buffer";
import {toast} from "react-toastify";


const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;


const ProductImageView = () => {

    const {requestId} = useParams();
    const [data, setData] = useState({request:{}});
    const [image, setImage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        async function getReq() {
            const response = await axios.get(`${Endpoints.PRODUCT_IMAGE}/${requestId}`);
            setData(response.data);

        }
        getReq();

    }, []);

    async function fetchImage(id) {
        let config = {};
        config["url"] = `${Endpoints.PRODUCT_IMAGE}/${id}/image`;
        config["responseType"] = "arraybuffer";

        let response = await axios2(config);
        let prefix = "data:" + response.headers["content-type"] + ";base64,";  //"data:image/png;base64,"; //
        let img = Buffer.from(response.data, "binary").toString("base64");
        setImage(prefix + img);
    }

    async function deleteProductImage() {
        const response = await axios.delete(`${Endpoints.PRODUCT_IMAGE}/${requestId}`);
    }

    useEffect(() => {
        if (data.request.status === 'SENT') {
            fetchImage(requestId)
        }
    }, [data.request.status]);
    const handleDelete = () => {
        deleteProductImage();
        toast.success("Амжилттай !");
        navigate("/image-requests")
    }
    return (
        <div className="m-4">
            <div  className="my-4">
                {( data.request.status === "SENT" ) &&
                <button  className="border p-2 rounded-md  border-orange-500" type="button" onClick={()=>handleDelete()}>
                    Устгах
                </button>
                }
                {(data.request.status === "DENIED" || data.request.status === "ACTIVATED" ) &&
                    <button className="border p-2 rounded-md  bg-red-500 text-white border-orange-500" type="button" onClick={()=>handleDelete()}>
                        Энэ мэдэгдэлийг устгах
                    </button>
                }
                <Link to={`/image-requests`} className="border p-2 rounded-md ml-2 border-orange-500" type="button">
                    Жагсаалт
                </Link>
            </div>

            {data.request.status === "DENIED" &&
            <div style={{border: "1px solid red", padding: "12px", marginBottom: "10px"}}>
                <b>Төлөв:</b> Буцсан<br/>
                <b>Шалтгаан:</b> {data.request.note}
            </div>
            }

            {data.request.status === "ACTIVATED" &&
                <div style={{border: "1px solid green", padding: "12px", marginBottom: "10px"}}>
                    <b>Төлөв:</b> Зөвшөөрсөн<br/>
                </div>
            }
            {data.request.status === "SENT" &&
                <div>
                <div style={{border: "1px solid orange", padding: "12px", marginBottom: "10px"}}>
                    <b>Төлөв:</b> Илгээсэн <br/>
                </div>
                <div>
                <img src={image} width={300}/>
                </div>
                </div>
            }

            <hr/>


        </div>
    )

}


export default ProductImageView

