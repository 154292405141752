/**
 * Created by gantushig on 11/6/15.
 */

import React, {useEffect, useRef} from "react";
import {removeLeadingZeros} from "./utils/productUtils";
import JsBarcode from "jsbarcode";


const ProductGTINViewFragment = ({
                             p
                         }) => {
    const containerRef = useRef(null);

    function download(){
        let canvas1 = document.getElementById("mycanvas");
        let url = canvas1.toDataURL("image/png");
        let link = document.createElement('a');
        link.download = p.barcode+'-'+p.name+'-'+p.packaging+'-'+p.packaging_unit+'.png';
        link.href = url;
        link.click();
    }
    // function download(){
    //     let canvas1 = document.getElementById("mycanvas");
    //     let url = canvas1.toDataURL("image/png");
    //     let link = document.createElement('a');
    //     link.download = p.barcode+'-GLN-'+p.name+'.png';
    //     link.href = url;
    //     link.click();
    // }
    useEffect(() => {
        if (p && p.barcode) {
            let format = null;
            let currBarcode = removeLeadingZeros(p.barcode);
            // if starts with 0 remove all 0
            if (currBarcode.length === 13) {
                format = "EAN13";
            } else if (currBarcode.length === 14) {
                format = "ITF14";
            } else if (currBarcode.length === 8) {
                format = "EAN8";
            }
            if (format) {
                const canvas = document.createElement("canvas");
                canvas.setAttribute("id", "mycanvas")
                let n = {
                    format: format,
                    textAlign: "center",
                    textPosition: "bottom",
                }
                if (containerRef.current.children[0]) {
                    containerRef.current.removeChild(containerRef.current.children[0])
                }
                JsBarcode(canvas, currBarcode, n);
                containerRef.current.appendChild(canvas);
            }
        }
    }, [p]);
    return (
        <>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Баркод/GTIN</th>
                <td className="border px-8 py-4">{p && p.barcode}
                    <div ref={containerRef}>

                    </div>

                    <button onClick={download} className="m-2 p-2 border-2">Download</button>
                </td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Нэр / Тодорхойлолт</th>
                <td className="border px-8 py-4">{p && p.name}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Брэнд Нэр</th>
                <td className="border px-8 py-4">{p && p.brand_name}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Савлалтын хэмжээ</th>
                <td className="border px-8 py-4">{p && p.packaging} - {p && p.packaging_unit}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Орц найрлага</th>
                <td className="border px-8 py-4">{p && p.constituent}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Шинж чанар</th>
                <td className="border px-8 py-4">{p && p.characteristics}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Зориулалт</th>
                <td className="border px-8 py-4">{p && p.handling}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Хэрэглэх заавар</th>
                <td className="border px-8 py-4">{p && p.instruction}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Хадгалах нөхцөл/хугацаа</th>
                <td className="border px-8 py-4">{p && p.storage}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Аль улсаас гарал үүсэлтэй</th>
                <td className="border px-8 py-4">{p && p.coo}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Бүтээгдэхүүний зураг байршсан линк</th>
                <td className="border px-8 py-4">{p && p.image_link}</td>
            </tr>
            <tr>
                <th className="bg-blue-100 border text-left px-8 py-4">Тухайн бараа бүтээгдэхүүн борлуулж байгаа улс</th>
                <td className="border px-8 py-4">{p && p.exp_countries_member}</td>
            </tr>

        </>
    )

}


export default ProductGTINViewFragment

