import {useEffect, useReducer} from "react";
import {fetchOwnCompany} from "./actions/actions";
import {ActionTypes} from "./Constants";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";


const initialState = {
    company:{},
    gcps: [],
    contacts:[],
    invoices:[],
    status:null

};

const reducer = (state, action) => {
    switch (action.type) {
        case ActionTypes.GET_COMPANY_SUCCESS:
            return Object.assign({}, state, {
                company: action.company,
                gcps: action.gcps,
                contacts: action.contacts,
                invoices: action.invoices,
                status: action.status
            })

        default:
            return state;
    }
};

const Dashboard = () => {
    const navigate = useNavigate()
    const [state, dispatch] = useReducer(reducer, initialState);
    const {company, contacts
    } = state;
    useEffect(() => {
        fetchOwnCompany(dispatch ).then (response => {
                if (response.data.invoices.length > 0) {
                    toast.success("Нэхэмжлэх ирсэн байна!");
                   navigate("/invoices", {state:{ invoices:response.data.invoices}})
                } else {
                    navigate("/company")
                }
            }
        ).catch(error=> {
            alert(error)
        });
    }, []);

    return (
        <>
            <h2>Dashboard (Protected)</h2>

            <div>Authenticated as </div>
        </>
    );
};

export default Dashboard;